import envConfig from '../../environments';

const BASE_API_PATH = 'api/v1/wiring-diagram'
export const DIAGRAM_LIST_PATH = BASE_API_PATH + '/get-diagram-list';
export const DIAGRAM_UPLOAD_HISTORY_PATH = BASE_API_PATH + '/get-upload-history';
export const DIAGRAM_COMPARISON_PATH = BASE_API_PATH + '/comparisons';
export const DIAGRAM_DETAIL_PATH = BASE_API_PATH + '/get-diagram-detail';
export const GET_FILTER_OPTIONS_PATH = BASE_API_PATH + '/get-filter-options';
export const DIAGRAM_PATH = BASE_API_PATH + '/diagram'

// Redrawing APIs
export const REDRAWING_PATH = BASE_API_PATH + '/redrawing'

// GRAPH API
export const GROUP_MEMBERS_API = `https://graph.microsoft.com/v1.0/groups/${envConfig.AZURE_GROUP_ID}/members`;
export const GROUP_OWNER_API = `https://graph.microsoft.com/v1.0/groups/${envConfig.AZURE_GROUP_ID}/owners`;
export const READ_USER_API = `https://graph.microsoft.com/v1.0/users`;

export const DIAGRAM_TYPES = Object.freeze({
    SCHEMATIC: 'schematic',
    WIRING: 'wiring',
});

export const COMPARISON_FILTER_OPTIONS = Object.freeze({
    ALL: 'All',
    TODAY: 'Today',
    LAST_3_DAYS: 'Last 3 days',
    LAST_7_DAYS: 'Last 7 days',
});

export const COMPARISON_STATUS_VALUES = Object.freeze({
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
});

export const DIAGRAM_UPLOADING_STATUS_VALUES = Object.freeze({
    SUCCESS: 'SUCCESS',
    SUCCESS_WITH_ERROR: 'SUCCESS_WITH_ERROR',
    MANUALLY_EDITED: 'MANUALLY_EDITED',
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    NOT_YET_EXTRACTED: 'NOT_YET_EXTRACTED',
});

export const GLOBAL_WIDTH_OFFSET = 215;

export const DEFAULT_GROUP = 'PSA';
export const DEFAULT_BRAND = 'Citroen';
export const DEFAULT_MODEL = 'C3';

export const URL_DELIMINATOR = '$$';
