import React, { useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { DIAGRAM_DETAIL_PATH, REDRAWING_PATH } from '../../constants';
import { UserContext } from '../../containers/App/appContext';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import envConfig from '../../../environments';

import { DIAGRAM_UPLOADING_STATUS_VALUES } from '../../constants';
import StatusButtonWithModal from '../StatusButtonWithModal';
import { getAccessToken } from '../../containers/LoginPage/auth';


function StatusTag(props) {
  const { value, shouldDisplayModifyButton, row } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { setAlert } = props;

  let bgColor = '#bbbbbb';
  let message = 'Diagram is not yet processed';
  let openModel = false;

  if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS) {
    bgColor = '#30CB83';
    message = 'Diagram is uploaded and ready to use'
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS_WITH_ERROR) {
    bgColor = '#C0DC91';
    // TODO: add information for missing components/pins
    message = 'TODO: add information for missing components/pins';
    openModel = true;
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.FAILED) {
    bgColor = '#D9534F';
    message = props.row.error;
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.NOT_YET_EXTRACTED) {
    bgColor = '#bbbbbb';
    message = 'Diagram is not yet uploaded';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.PROCESSING) {
    bgColor = '#9B59B6';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.PENDING) {
    bgColor = '#5BC0DE';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.MANUALLY_EDITED) {
    bgColor = '#1C794E';
    openModel = true;
  }

  const handleStatusClick = (event) => {
    event.stopPropagation()
    setModalOpen(shouldDisplayModifyButton && openModel);
  }

  return (
    <StatusButtonWithModal
      diagramId={props?.id}
      imageUri={row?.imageUri}
      bgColor={bgColor}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleStatusClick={handleStatusClick}
      value={value}
      setAlert={setAlert}
    />
  )
}

function DownloadTag(props) {
  const value = props.row.status;
  const id = props.row.id;
  const diagramName = props.row.diagramName;
  const { currUser, setCurrUser } = useContext(UserContext);

  const params = new URLSearchParams({
    diagram_id_list: id + '$' + diagramName,
    output_type: 'excel'
  });

  const handleDownloadClick = async (event) => {
    event.stopPropagation()
    const accessToken = await getAccessToken(currUser, setCurrUser)
    fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_DETAIL_PATH}?${params.toString()}`, {
      method: 'GET',
      headers: { 'auth': accessToken },
    })
      .then((res) => res.json())
      .then(data => {
        if (data && data.body) {
          const base64String = data.body;
          const binaryData = atob(base64String);
          const uint8Array = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: 'application/zip' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = diagramName + '.zip';
          link.click();
        } else {
          console.error('Invalid response format');
        }
      })
      .catch(console.error);
  };

  if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS) {
    return (
      <IconButton onClick={handleDownloadClick} >
        <DownloadIcon />
      </IconButton >
    );
  }
  return null;
}

function Redraw(props) {
  const status = props.row.status
  const { setAlert } = props
  const { currUser, setCurrUser } = useContext(UserContext);

  const handleRedrawOnClick = async (event) => {
    event.stopPropagation()
    const accessToken = await getAccessToken(currUser, setCurrUser)
    const { id, diagramName } = props.row
    setAlert && setAlert({
        severity: 'info',
        title: 'Info',
        message: `Diagram ${diagramName} is redrawing, please wait....`
    })

    fetch(`${envConfig.BACKEND_SERVER}/${REDRAWING_PATH}/${id}`, {
      method: 'GET',
      headers: { 'auth': accessToken },
    })
      .then((res) => res.json())
      .then(data => {
        if (data && data.presigned_url) {
          setAlert && setAlert({
            severity: 'success',
            title: 'Success',
            message: `Redraw diagram ${diagramName} successfully`
          })
          window.open(data?.presigned_url, '_blank', 'noopener,noreferrer');
        } else {
          setAlert && setAlert({
              severity: 'error',
              title: 'Error',
              message: `Redraw diagram ${diagramName} got invalid response format `
          })
        }
      })
      .catch(console.error);
  } 

  if (![DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS].includes(status)) return
  
  return (
    <IconButton data-testid='redraw-btn-id' onClick={handleRedrawOnClick}>
        <EditIcon />
    </IconButton>
  )
}


export default function DiagramDataGrid(props) {
  const { rows, rowCountState, handleRowClick } = props;
  const { isLoading } = props;
  const { paginationModel, setPaginationModel } = props;
  const { customColumns } = props;  // Used for testing
  const { setAlert } = props;

  let columns;
  if (customColumns != null) {
    columns = customColumns;
  } else {
    columns = [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'diagramName',
        headerName: 'Diagram Name',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'diagramType',
        headerName: 'Diagram Type',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'group',
        headerName: 'Group',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'model',
        headerName: 'Model',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'domainValue',
        headerName: 'Domain Value',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'functionValue',
        headerName: 'Function Value',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'engineCode',
        headerName: 'Engine Code',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <StatusTag
              setAlert={setAlert}
              shouldDisplayModifyButton={true}
              {...params}
            />
          )
        },
        minWidth: 200,
      },
      {
        field: 'downloadStatus',
        headerName: 'Excel',  // use shorter name to save space
        headerAlign: 'center',
        align: 'center',
        renderCell: DownloadTag,
        width: 50,
      },
      {
        field: 'redraw',
        headerName: 'Redraw',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <Redraw data-testid='redraw-col-id' setAlert={setAlert} {...params}/>
          )
        },
        width: 75,
      },
    ];
  }

  const onPageChange = (model) => {
    setPaginationModel({ ...paginationModel, page: model.page })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        disableVirtualization // this line support unit test for loading all document
        data-testid='diagram-table'
        autoPageSize
        autoHeight
        rows={rows}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        paginationMode='server'
        onPaginationModelChange={onPageChange}
        onRowClick={handleRowClick}
        disableColumnMenu
      />
    </Box>
  );
}
