import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    DIAGRAM_TYPES,
    GLOBAL_WIDTH_OFFSET,
    DEFAULT_GROUP,
    DEFAULT_BRAND,
    DEFAULT_MODEL,
} from '../../constants';
import { getDiagramSignedUploadUrl, keyExistsBy } from './uploadDiagram';
import { Alert, AlertTitle, Box, Grid, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FileUpload from 'react-material-file-upload';

import axios from 'axios';
import DiagramCard from '../../components/DiagramCard';
import useMessageWithTimeout from '../../common/messageWithTimeoutGenerator';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';

import '../../../styles/form.css';
import DuplicatedDiagramDialog from '../../components/Dialog/duplicatedDiagramDialog';


function UploadPage(props) {
    const { state } = useLocation();

    // multiple is indicator for case re-uploading (admit only one)
    const [multiple] = useState(state?.multiple !== false);

    const [files, setFiles] = useState([]);
    const [filename, setFilename] = useState(state?.filename || '');
    const [diagramType, setDiagramType] = useState(state?.diagramType || DIAGRAM_TYPES.WIRING);

    // In the very first upload, the Brand and Model are empty
    // Case re-upload that diagram, the hash key has been changed
    // as the Back-End has re-filled those values as Citroen and C3, correspondingly.
    // So we have to set default values here from the Front-End.
    // Perhaps, in the future, we need to make those fields as required ones.
    const [model, setModel] = useState(state?.model || DEFAULT_MODEL);
    const [brand, setBrand] = useState(state?.brand || DEFAULT_BRAND);
    const [group, setGroup] = useState(state?.group || DEFAULT_GROUP);

    const [domainValue, setDomainValue] = useState(state?.domainValue || '');
    const [functionValue, setFunctionValue] = useState(state?.functionValue || '');
    const [engineCode, setEngineCode] = useState(state?.engineCode || '');
    const [successMsg, setSuccessMsg] = useMessageWithTimeout('', 5000);
    const [errorMsg, setErrorMsg] = useMessageWithTimeout('', 5000);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [duplicatedDiagramDialogOpen, setDuplicatedDialogOpen] = useState(false);
    const [duplicateList, setDuplicateList] = useState([]);
    const scrollbarWidth = getScrollbarWidth();
    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const { forceOpenDialog } = props;  // Used for testing

    useResizeHandler(setContainerDimensions);

    const fileChange = (files) => {
        if ( !multiple && files.length !== 0 ) {
            files = [files[0]]; // Take only the first file if it's for re-uploading
        }
        if (files.length === 1) {
            setFilename(files[0].name);
        }
        setFiles(Array.from(files).map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    };


    const handleClose = () => {
        setDialogOpen(false);
    };

    const upload = async (diagramName, metadata, file, failedFiles) => {
        const PUTEndpoint = await getDiagramSignedUploadUrl(diagramName, metadata, 'putObject');
        const options = {
            headers: {
                'Content-Type': file.type,
                'Access-Control-Allow-Origin': '*',
                'x-amz-meta-diagram-name': diagramName,
                'x-amz-meta-diagram-type': diagramType,
                'x-amz-meta-model': model,
                'x-amz-meta-brand': brand,
                'x-amz-meta-group': group,
                'x-amz-meta-domain-value': domainValue,
                'x-amz-meta-function-value': functionValue,
                'x-amz-meta-engine-code': engineCode,
            }
        };

        const response = await axios.put(PUTEndpoint, file, options);
        if (response.status !== 200) {
            failedFiles.push(file.name);
        }
    }

    const renderPopup = () => {
        return (
            <React.Fragment>
                <Dialog
                    open={dialogOpen || forceOpenDialog}
                    onClose={handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        {'Replace duplicated diagrams?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            There are {duplicateList.length} diagrams already upload. Do you want to replace them?
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-description'>
                            Please note that if you choose to replace a diagram and it is being in comparison progress, 
                            that diagram will be rejected without notification.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Skip All
                        </Button>
                        <Button onClick={(e) => {
                            handleUpload(e, duplicateList, false)
                            handleClose()
                        }}>
                            Replace All
                        </Button>
                        <Button onClick={() => {
                            setDialogOpen(false);
                            setDuplicatedDialogOpen(true);
                        }
                        } >
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        );
    }

    const handleUpload = async (e, files, shouldCheckKeyExist) => {
        e.preventDefault();
        if (files.length === 0) {
            setErrorMsg('No files selected for upload');
            return;
        }
        let failedFiles = [];
        let duplicatedDiagrams = [];
        setLoadingMsg('Diagrams are being uploaded. Please be patient...');
        // This loop accidentally overwrites the stated filename
        const uploadPromises = files.map(async (file) => {
            let diagramName = file?.name.split('.svg')[0];
            if ( multiple && files.length === 1 ) {
                // case first upload, allow changing name
                // -> get from overwritten setFilename
                diagramName = filename.split('.svg')[0];
            } else if ( !multiple ) {
                // case re-upload, not allow changing name
                // -> get from stated filename
                diagramName = state?.filename.split('.svg')[0];
            }

            const metadata = {
                'diagram-name': diagramName,
                'diagram-type': diagramType,
                'model': model,
                'brand': brand,
                'group': group,
                'domain-value': domainValue,
                'function-value': functionValue,
                'engine-code': engineCode,
            }

            try {
                const keyExists = shouldCheckKeyExist && await keyExistsBy(diagramName, metadata);
                if (keyExists) {
                    duplicatedDiagrams.push(file)
                } else {
                    await upload(diagramName, metadata, file, failedFiles);
                }
            } catch (error) {
                failedFiles.push()
            }
        });
        try {
            await Promise.all(uploadPromises);
            setLoadingMsg('');
            if (duplicatedDiagrams.length > 0) {
                setDuplicateList(duplicatedDiagrams)
                setDialogOpen(true);
            }
            if (failedFiles.length === 0) {
                setSuccessMsg('Upload diagrams successfully');
            } else {
                setErrorMsg(`Upload failed for the following file(s): ${failedFiles.join(', ')}`);
            }
        } catch (error) {
            setErrorMsg(`Upload failed with message: ${error}`);
        }
    };

    const displayFiles = () => {
        return (
            <Box
                maxHeight
                component={Paper}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    bgcolor: 'lightgray',
                    width: '55%',
                    height: '100%',
                    mt: 1,
                    ml: 1,
                }}
            >
                {
                    (files?.length === 0) &&
                    <Typography
                        sx=
                        {{
                            fontSize: 20,
                            p: 2,
                            width: '100%'
                        }}
                    >
                        No files selected
                    </Typography>
                }
                <Grid container sx={{
                    mb: 4,
                    p: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {
                        files && files.map((image) => {
                            return (
                                <Grid item sx={{
                                    mb: 4,
                                    p: 1,

                                }}>
                                    <DiagramCard img={image} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>)
    }

    const renderForm = () => {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                '& .MuiTextField-root': { m: 1, width: 510 },
            }}>
                <form onSubmit={(e) => handleUpload(e, files, true)}>
                    <div>
                        {files.length === 1
                            ? (
                                <TextField
                                    id='filenameInput'
                                    value={filename ? filename : ''}
                                    label='File Name'
                                    variant='outlined'
                                    onChange={e => setFilename(e.target.value)}
                                />
                            )
                            : files.length === 0
                                ? (
                                    <TextField
                                        id='filenameInput'
                                        value={state?.filename || ''}
                                        label='File Name'
                                        variant='outlined'
                                        onChange={e => setFilename(e.target.value)}
                                    />
                                )
                                : ''
                        }
                    </div>
                    <div>
                        <TextField
                            id='diagramInput'
                            select
                            label='Select Diagram type'
                            defaultValue={DIAGRAM_TYPES.WIRING}
                            onChange={e => setDiagramType(e.target.value)}
                        >
                            <MenuItem key={DIAGRAM_TYPES.WIRING} value={DIAGRAM_TYPES.WIRING}>
                                Wiring
                            </MenuItem>
                            <MenuItem key={DIAGRAM_TYPES.SCHEMATIC} value={DIAGRAM_TYPES.SCHEMATIC}>
                                Schematic
                            </MenuItem>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id='groupInput'
                            select
                            label='Select Group'
                            defaultValue='PSA'
                            onChange={e => setGroup(e.target.value)}
                        >
                            <MenuItem key='PSA' value='PSA'>
                                PSA
                            </MenuItem>
                            <MenuItem key='Other' value='Other'>
                                Other
                            </MenuItem>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id='brandInput'
                            value={brand}
                            label='Brand'
                            onChange={e => setBrand(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField
                            id='modelInput'
                            value={model}
                            label='Model'
                            onChange={e => setModel(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField
                            id='domainValueInput'
                            value={domainValue}
                            label='Domain Value'
                            onChange={e => setDomainValue(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField
                            id='functionValueInput'
                            value={functionValue}
                            label='Function Value'
                            onChange={e => setFunctionValue(e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField
                            id='engineCodeInput'
                            value={engineCode}
                            label='Engine Code'
                            onChange={e => setEngineCode(e.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '12px', marginLeft: '8px', width: '510px' }}>
                        <FileUpload
                            value={files}
                            onChange={e => fileChange(e)}
                            multiple={true}
                            buttonText={'Add files'}
                            accept='.svg'
                        />
                    </div>
                    <div style={{ marginTop: '12px',  marginBottom: '10px',  marginLeft: '445px' }}>
                        <Button type='submit'>Upload</Button>
                    </div>
                </form>
            </Box>
        )
    }

    const renderAlert = () => {
        return (
            <Box>
                {
                    errorMsg !== '' &&
                    <Alert severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }
                {
                    successMsg !== '' &&
                    <Alert severity='success'>
                        <AlertTitle>Success</AlertTitle>
                        {successMsg}
                    </Alert>
                }
                {
                    loadingMsg !== '' &&
                    <Alert severity='info'>
                        <AlertTitle>Info</AlertTitle>
                        {loadingMsg}
                    </Alert>
                }
            </Box>
        )
    }

    const removeAfterUpload = (index) => {
        let newFiles = duplicateList;
        newFiles.splice(index, 1);
        setDuplicateList(newFiles);
        if (newFiles.length === 0) {
            setDuplicatedDialogOpen(false);
        }
    }
    return (
        <Box
            sx={{ bgcolor: 'lightgray' }}
            // height={containerDimensions.height}
            width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
        >
            {
                renderAlert()
            }
            {
                renderPopup()
            }
            {
                duplicatedDiagramDialogOpen && duplicateList.map((diagram, index) => {
                    return (
                        <div>
                            <DuplicatedDiagramDialog
                                diagram={diagram}
                                index={index}
                                shouldOpenDialog={duplicatedDiagramDialogOpen}
                                handleReplace={handleUpload}
                                removeAfterUpload={removeAfterUpload}
                            />

                        </div>
                    )
                })
            }
            <Box
                className='container'
                maxWidth
                sx={{ my: 0.5, px: 0.5, pb: 0.5, pt: 0.5 }}
                justifyContent={'space-between'}
                display={'flex'}
            >
                <Paper sx={{
                    height: 75,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    px: 1
                }}>
                    <Typography variant='h4' component='h5' sx={{ alignItems: 'center' }}>
                        Upload Diagram
                    </Typography>

                </Paper>
            </Box>
            <Box
                maxWidth
                maxHeight
                sx={{ my: 0.5, px: 0.5, pb: 0.5 }}
                justifyContent={'space-between'}
                display={'flex'}
            >
                <Paper
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {
                        displayFiles()
                    }
                    {
                        renderForm()
                    }
                </Paper>
            </Box>
        </Box>
    );
}

export default UploadPage;
