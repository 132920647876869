import {
    Autocomplete,
    CircularProgress,
    autocompleteClasses,
    Box,
} from '@mui/material';

import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const CustomAutocomplete = (props) => {
    // Autocomplete doesn't provide onClick callback.
    // The only and simple way is wrap the Autocomplete with Box component.
    const { onClick } = props;
    return (
        <Box onClick={onClick}>
            <Autocomplete
                popupIcon={
                    props?.loading ?
                        <CircularProgress
                            data-testid='loading-indicator-id'
                            size='1.5rem' />
                        : <ArrowDropDownIcon />
                }
                {...props}
            />
        </Box>
    )
}

const StyledAutocomplete = styled(CustomAutocomplete)(({ theme }) => ({
    [`& .${autocompleteClasses.clearIndicator}`]: {
        backgroundColor: 'white',
        color: '#2286F7'
    },
    [`& .${autocompleteClasses.popupIndicator}`]: {
        backgroundColor: 'white',
        color: '#2286F7'
    },
    [`& .${autocompleteClasses.inputRoot}`]: {
        minHeight: 50,
        width: '100%',
        display: 'flex'
    }
}));

export default StyledAutocomplete;
