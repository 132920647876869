import { ButtonProps, CSSObject, IconButton, styled } from '@mui/material';

const StyledIconButton = styled(IconButton)<{
    variant?: Exclude<ButtonProps['variant'], 'text'>;
}>(({ theme, variant, color }) => {
    const overrides: CSSObject = {};

    const colorAsVariant = color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color;
    if (variant === 'contained') {
        overrides.backgroundColor = theme.palette[colorAsVariant].main;
        overrides.color = theme.palette[colorAsVariant].contrastText;
        overrides[':hover'] = {
            backgroundColor: theme.palette[colorAsVariant].dark,
        };
    }

    if (variant === 'outlined') {
        overrides.backgroundColor = theme.palette[colorAsVariant].contrastText
        overrides.color = theme.palette[colorAsVariant].main;
        overrides.borderRadius = 50;
        overrides[':hover'] = {
            backgroundColor: theme.palette[colorAsVariant].light,
            color: theme.palette[colorAsVariant].contrastText,

        };
    }

    return {
        ...overrides,
    };
});

export default StyledIconButton;