import { Button, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import MissingInfoAlert from '../../containers/DiagramMissingInfoPage/MissingInfoAlert';
import { DIAGRAM_UPLOADING_STATUS_VALUES } from '../../constants';


export default function StatusButtonWithModal(props) {
    const { bgColor, modalOpen, setModalOpen, handleStatusClick, value, setAlert } = props;
    const { diagramId, imageUri } = props

    return (
        <>
            <Button
                style={{ color: 'white', background: bgColor, width: 160 }}
                onClick={handleStatusClick}
            >
                {value}
            </Button>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'white',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        width: 400,
                    }}
                >
                    <h2 id='modal-title'>Information</h2>
                    <MissingInfoAlert
                        diagramId={diagramId}
                        imageUri={imageUri}
                        shouldDisplayUpdateInformation={value === DIAGRAM_UPLOADING_STATUS_VALUES.MANUALLY_EDITED}
                        setAlert={setAlert}
                    />
                </Box>
            </Modal >
        </>
    );
}
