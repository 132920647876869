const ERROR_TYPES = Object.freeze({
    REQUEST: 'Request_ResourceNotFound',
    INVALID_AUTH: 'InvalidAuthenticationToken',
});

export const getErrorMessages = (error, fullEmail) => {
    switch (error?.code) {
        case ERROR_TYPES.REQUEST:
            return `User ${fullEmail} not found`;
        case ERROR_TYPES.INVALID_AUTH:
            return 'JWT is not well formed';
        default:
            return 'Unknown error';
    }
}
