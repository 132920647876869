import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import MissingInfoTreeView from '../../components/MissingInfoTreeView';
import './style.css'
import { useNavigate, useParams } from 'react-router-dom';
import CustomTabPanel from '../../components/TabPanel';
import MissingPins from '../../components/MissingInfoTreeView/MissingPins';
import { MissingInfoContext, UserContext } from '../App/appContext';
import { fetchMissingInfo, updateMissingInfo } from './fetchMissingInfo';
import MissingComponentCard from '../../components/MissingComponentCard';

const COUNTER = 5

function DiagramMisingInfoPage(props) {
    const navigate = useNavigate()
    const { diagramId } = useParams()
    const { missingInfo, setMissingInfo } = useContext(MissingInfoContext)
    const { currUser, setCurrUser } = useContext(UserContext)

    const [loadingComponents, setLoadingComponents] = useState(false)
    const [loadingPins, setLoadingPins] = useState(false)
    const [selectedConnectorMap, setSelectedConnectorMap] = useState({})
    const [alert, setAlert] = useState(null)
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
    const [openCancelDialog, setOpenCancelDialog] = useState(false)
    const [counter, setCounter] = useState(-1)

    const connectors = missingInfo?.connectors || []
    const components = missingInfo?.components || []
    const pinsByComponentName = missingInfo?.pinsByComponentName || {}
    let alertTimer;

    useEffect(() => {
        if (Object.keys(missingInfo).length === 0) {
            fetchMissingInfo(diagramId, setMissingInfo, updateLoading, currUser, setCurrUser)
        }
    }, [missingInfo, currUser])

    useEffect(() => {
        if (alert && alert?.severity === 'success') {
            setCounter(COUNTER)
            clearTimeout(alertTimer)
            setTimeout(() => setAlert(null), 5000)
        }
    }, [alert])

    useEffect(() => {
        if (counter === -1) return
        if (counter <= 0) {
            setCounter(-1)
        } else {
            setTimeout(() => setCounter(counter - 1), 1000)
        }
    }, [counter])

    const onLeaveThePage = () => {
        navigate(`/wiring-diagram`);
    }

    const updateLoading = (state) => {
        setLoadingComponents(state)
        setLoadingPins(state)
    }

    const onComponentNameChange = (newComponentName, idx) => {
        let newComponents = missingInfo?.components || []
        if (newComponents.length <= idx) {
            console.log('Error: Index of component is out of bounds')
            return
        }
        newComponents[idx] = {
            ...newComponents[idx],
            component_name: newComponentName
        }
        setMissingInfo({
            ...missingInfo,
            components: newComponents
        })
    }

    const onConnectorChange = (componentName, connectorId, index, newItem) => {
        const newPinChanges = pinsByComponentName?.[componentName]?.[connectorId] || []
        if (newPinChanges.length === 0) {
            return
        }
        newPinChanges[index] = newItem
        const changes = {
            ...pinsByComponentName,
            [componentName]: {
                ...pinsByComponentName[componentName],
                [connectorId]: newPinChanges
            }
        }
        setMissingInfo({
            ...missingInfo,
            pinsByComponentName: changes || {}
        })
    }

    const onMainConnectorChange = (componentName, oldConnectorId, connector) => {
        let newPinChanges = pinsByComponentName?.[componentName]?.[oldConnectorId] || []
        if (newPinChanges.length === 0) {
            return
        }
        for (let index in newPinChanges) {
            newPinChanges[index] = {
                ...newPinChanges[index],
                'connector_id': connector?.id,
                'connector_diagram_id': connector?.connector_diagram_id,
            }
        }
        const changes = {
            ...pinsByComponentName,
            [componentName]: {
                ...pinsByComponentName[componentName],
                [oldConnectorId]: newPinChanges
            }
        }
        setMissingInfo({
            ...missingInfo,
            pinsByComponentName: changes || {}
        })
    }

    const onAction = (connectorId, selectedConnector) => {
        setSelectedConnectorMap({
            ...selectedConnectorMap,
            [connectorId]: selectedConnector
        })
    }

    const onUpdateButtonClick = () => {
        setOpenSubmitDialog(false)
        let pins = []
        for (let componentName in missingInfo?.pinsByComponentName) {
            const pinsMap = missingInfo?.pinsByComponentName?.[componentName]
            for (let connectorId in pinsMap) {
                const updatedPinList = pinsMap?.[connectorId].filter(item => item?.connector_id !== undefined)
                pins = [...pins, ...updatedPinList]
            }
        }
        const payload = {
            components: missingInfo?.components || [],
            pins: pins
        }
        updateMissingInfo(diagramId, payload, setAlert, currUser, setCurrUser)
    }

    return (
        <CustomTabPanel
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                overflowY: 'auto',
                bgcolor: 'white',
                m: 0.5,
            }}
        >
            {
                alert && alert?.message !== '' &&
                <Alert
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        whiteSpace: 'pre-line',
                    }}
                    severity={alert?.severity}
                >
                    <AlertTitle>{alert?.title}</AlertTitle>
                    {alert?.message}
                    {`\nAlert will close in ${counter}s`}
                </Alert>
            }
            <Dialog onClose={() => setOpenSubmitDialog(false)} open={openSubmitDialog}>
                <DialogTitle>Update</DialogTitle>
                <DialogContent>
                    Would you like to update missing information?<br></br>
                    If you update information, it will replace the current one.
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            backgroundColor: '#aaaaaa',
                            '&:hover': { backgroundColor: '#8c8b8b' }
                        }}
                        onClick={() => setOpenSubmitDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={onUpdateButtonClick}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={() => setOpenCancelDialog(false)} open={openCancelDialog}>
                <DialogTitle>Cancel</DialogTitle>
                <DialogContent>
                    Would you like to cancel and leave the page?
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            backgroundColor: '#aaaaaa',
                            '&:hover': { backgroundColor: '#8c8b8b' }
                        }}
                        onClick={() => setOpenCancelDialog(false)}>
                        Stay
                    </Button>
                    <Button onClick={onLeaveThePage}>
                        Leave
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    overflow: 'hidden',
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                <Box
                    data-testid='mising-info-box'
                    sx={{
                        m: 1,
                        pl: 2,
                    }}
                >
                    <p data-testid='missingPinsId'>
                        &bull;
                        <span class='tab'>
                            {`Missing Pins: ${missingInfo?.totalPins}`}
                        </span>
                    </p>
                    <p>
                        &bull;
                        <span class='tab'>
                            {`Updated Pins: ${missingInfo?.updatedPins || 0}`}
                        </span>
                    </p>
                    <p>
                        &bull;
                        <span class='tab'>
                            {`Not Updated Pins: ${missingInfo?.totalPins - missingInfo?.updatedPins}`}
                        </span>
                    </p>
                    <hr />

                    <p data-testid='missingComponentId'>
                        &bull;
                        <span class='tab'>
                            {`\tMissing Components: ${components && components.length}`}
                        </span>
                    </p>
                    <p>
                        &bull;
                        <span class='tab'>
                            {`Updated Components: ${missingInfo?.updatedComponents || 0}`}
                        </span>
                    </p>
                    <p>
                        &bull;
                        <span class='tab'>
                            {`Not Updated Components: ${missingInfo?.components?.length - missingInfo?.updatedComponents}`}
                        </span>
                    </p>
                    <hr />

                </Box>
                <MissingInfoTreeView
                    labelId='componentsViewId'
                    label={`Components:`}
                    loading={loadingComponents}
                    setLoading={setLoadingComponents}
                    element={
                        <List sx={{
                            listStyleType: 'circle',
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                p: 1
                            },
                            p: 0

                        }}>
                            {
                                components && components.map((component, idx) => {
                                    return (
                                        <ListItem>
                                            <MissingComponentCard
                                                index={idx}
                                                item={component}
                                                onChange={onComponentNameChange}
                                            />
                                        </ListItem>
                                    )
                                })

                            }

                        </List>

                    }
                />
                <MissingInfoTreeView
                    labelId='pinsViewId'
                    label={`Pins:`}
                    loading={loadingPins}
                    element={
                        Object.keys(pinsByComponentName).map(componentName => {
                            const pinsByConnectorId = pinsByComponentName[componentName] || {}
                            return (
                                <MissingInfoTreeView
                                    label={componentName}
                                    loading={false}
                                    element={
                                        Object.keys(pinsByConnectorId).map(connectorId => {
                                            return (
                                                <MissingPins
                                                    pinList={pinsByConnectorId[connectorId] || []}
                                                    connectorId={connectorId}
                                                    componentName={componentName}
                                                    connectorList={connectors && connectors[componentName] || []}
                                                    onConnectorChange={onConnectorChange}
                                                    onMainConnectorChange={onMainConnectorChange}
                                                    selectedConnector={selectedConnectorMap?.[connectorId] || null}
                                                    setSelectedConnector={
                                                        (selectedConnector, connectorId) => onAction(selectedConnector, connectorId)}
                                                />
                                            )
                                        })

                                    }

                                />
                            )
                        })
                    }
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    mx: 3,
                    my: 1,
                }}>

                    <Button
                        onClick={() => setOpenCancelDialog(true)}
                        sx={{
                            backgroundColor: '#aaaaaa',
                            '&:hover': { backgroundColor: '#8c8b8b' }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => setOpenSubmitDialog(true)}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </CustomTabPanel>
    )
}

export default DiagramMisingInfoPage