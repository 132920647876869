import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


export default function DiagramComparisonDialog(props) {
    const {isOpen, onClose, deleteSelectedRow } = props;

    if (!isOpen) return null;

    return (
        <React.Fragment>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {'Delete Comparison?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Do you want to delete this comparison?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        sx={{ backgroundColor: '#aaaaaa', '&:hover': {backgroundColor: '#aaaaaa'}}}
                    >
                        Cancel
                    </Button>
                    <Button  onClick={() => {
                        deleteSelectedRow()
                        onClose()
                    }} sx={{ backgroundColor: '#FF3D33', '&:hover': {backgroundColor: '#FF3D33'}}}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
