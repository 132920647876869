import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Component } from 'react'
import DiagramPicker from '../DiagramPickerPage/DiagramPicker'
import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material'
import StyledIconButton from '../../components/StyledIconButton'
import { AddOutlined, DeleteOutline } from '@mui/icons-material'
import { createComparison } from '../DiagramPickerPage/fetchData'
import { isArrayEmptyOrNull, isObjectEmptyOrNull } from '../../common/utils'
import { v4 as uuidv4 } from 'uuid';

export default class AddComparisonDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            diagrams: this.props?.diagrams || [],
            createList: [{ id: uuidv4() }],
            showAlert: false,
            isInQueue: false,
            loading: false,
        }
    }

    componentDidMount() {
        this.resetState()

    }

    componentDidUpdate(prevProps) {
        if (this.state.diagrams.length !== prevProps.diagrams.length) {
            this.setState({
                diagrams: prevProps.diagrams
            })
        }
    }

    resetState() {
        this.setState({
            createList: [{ id: uuidv4() }],
            showAlert: false,
            loading: false,
        })
    }

    isValidForm() {
        let valid = true;
        this.state.createList.forEach(item => {
            if (
                isObjectEmptyOrNull(item) ||
                isObjectEmptyOrNull(item?.comparator) ||
                isArrayEmptyOrNull(item?.comparateeList)
            ) {
                valid = false
            }
        })
        return valid
    }

    renderDiagramPicker(item, idx) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    py: 0.5,
                    mb: 1,
                    justifyContent: 'center',
                    overflow: 'auto',
                    width: '100%'
                }}
            >
                <DiagramPicker
                    id={item?.id}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '90%',
                        height: 110,
                        gap: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        overflow: 'auto',   
                    }}
                    index={idx}
                    filteredDiagrams={this.state.diagrams}
                    filteredComparateeDiagrams={this.state.diagrams}
                    originDiagram={item?.comparator || null}
                    targetDiagrams={item?.comparateeList || []}
                    onChange={(idx, value) => this.onDiagramPickerChange(idx, value)}
                    shouldShowSimilarity={false}
                />
                <StyledIconButton
                    sx={{ width: 50, height: 50 }}
                    variant='outlined'
                    size='medium'
                    onClick={() => this.onDeleteDiagramPicker(idx)}
                >
                    <DeleteOutline />
                </StyledIconButton>
            </Box>

        )
    }
    onDiagramPickerChange(index, newValue) {
        if (index > this.state.createList.length) {
            console.log('Error: Index out of array')
            return
        }
        if (this.state.createList.length === index) {
            this.setState({
                createList: [
                    ...this.state.createList,
                    { ...newValue }
                ]
            })
        } else {
            const newList = this.state.createList.map((value, idx) => {
                if (idx === index) {
                    return {
                        ...value,
                        ...newValue
                    }
                }
                return value
            })
            this.setState({
                createList: newList
            })
        }
    }

    onCreateButtonClick() {
        this.setState({
            loading: true
        })
        const filterCreateList = this.state.createList.filter(item => !isObjectEmptyOrNull(item))
        const requestPromises = filterCreateList.map((item, index, arr) => {
            const originDiagram = item?.comparator;
            const targetDiagrams = item?.comparateeList;

            const comparateeIdList = targetDiagrams
                .map((diagram) => `${diagram?.diagram_id}$${diagram?.diagram_name}`)
                .join(',');
            const comparatorId = `${originDiagram?.diagram_id}$${originDiagram?.diagram_name}`;

            if (comparatorId === '' || comparateeIdList === '') {
                this.setState({
                    showAlert: true
                })
                return;
            }
            return createComparison(
                item?.id,
                comparatorId,
                comparateeIdList,
                this.props.currUser,
                this.props.setCurrUser,
            )
        })

        Promise.all(requestPromises)
            .then((result) => {
                if (result) {
                    this.props.setShowSuccessPopUp(true)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })

    }


    onDeleteDiagramPicker(removeIndex) {
        let newList = this.state.createList.filter((item, index) => index !== removeIndex)
        this.setState({
            createList: newList
        })
    }

    render() {
        return (
            <Dialog
                onClose={() => this.props.setOpen(false)}
                open={this.props.open}
                maxWidth='lg'
                fullWidth
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    Add New Comparison(s)

                </DialogTitle>
                {
                    this.state.showAlert &&
                    <Alert
                        severity='info'
                    >
                        <AlertTitle>Required field is empty</AlertTitle>
                        Missing comparator or comparatee(s) will be ignore
                    </Alert>
                }
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>

                    {
                        this.state.createList.map((item, idx) => this.renderDiagramPicker(item, idx))
                    }

                    <StyledIconButton
                        variant='outlined'
                        size='small'
                        sx={{
                            width: 50,
                            height: 50,
                        }}
                        onClick={() => this.setState({ createList: [...this.state.createList, { id: uuidv4() }] })}
                    >
                        <AddOutlined fontSize='medium' />
                    </StyledIconButton>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            backgroundColor: '#aaaaaa',
                            '&:hover': { backgroundColor: '#8c8b8b' }
                        }}
                        onClick={() => this.props.setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.onCreateButtonClick()}
                        startIcon={this.state.loading ?
                            <CircularProgress
                                sx={{
                                    color: 'white'
                                }}
                                size={16} /> :
                            null}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

