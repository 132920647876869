import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { signIn } from './auth';
import envConfig from '../../../environments';
import { isObjectEmptyOrNull } from '../../common/utils';


const LoginPage = ({ callback }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const useSSO = envConfig.USE_SSO;
    const [alert, setAlert] = useState({})

    const handleLogIn = async () => {
        try {
            setIsLoading(true)
            await signIn(username, password)
                .then(() => callback())
        } catch (err) {
            console.log(err)
            setAlert({
                ...alert,
                severity: 'error',
                title: 'Login Error',
                message: err
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleLoginWithSSO = () => {
        const params = new URLSearchParams()
        params.append('identity_provider', envConfig.IDENTITY_PROVIDER)
        params.append('redirect_uri', envConfig.APP_DIRECT_URL)
        params.append('response_type', 'CODE')
        params.append('client_id', envConfig.USER_POOL_APP_CLIENT_ID)
        params.append('scope', 'email openid')

        const url = `https://${envConfig.USER_POOL_DOMAIN}/oauth2/authorize?${params.toString()}`
        window.location.href = url
    }

    return (
        <Container component='main' sx={{ width: '100vw', height: '100vh' }}>
            <Box sx={{
                flexDirection: 'column',
                display: 'flex',
                marginTop: 8,
                px: 4,
                py: 6,
                alignItems: 'center'
            }}>
                {
                    !isObjectEmptyOrNull(alert) &&
                    <Alert
                        onClose={() => setAlert({})}
                        severity={alert?.severity}
                    >
                        <AlertTitle>{alert?.title}</AlertTitle>
                        {alert?.message}
                    </Alert>
                }
                <Typography component='h1' variant='h5'>Log In</Typography>
                <TextField
                    error={username.length === 0 ? true : false}
                    margin='normal'
                    id='username'
                    label='Username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{ width: 400 }}
                    required />
                <TextField
                    error={password.length === 0 ? true : false}
                    margin='normal'
                    id='password'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ width: 400 }}
                    required />
                {
                    (isLoading && <CircularProgress />) ||
                    (!isLoading &&
                        <div>
                            <Box sx={{
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}>
                                <Button
                                    disabled={
                                        username.length === 0 ||
                                        password.length === 0
                                    }
                                    variant='contained'
                                    onClick={handleLogIn}
                                    sx={{
                                        height: 50,
                                        width: 200,
                                        alignItems: 'center'
                                    }}>
                                    Login
                                </Button>
                                {
                                    useSSO &&
                                    <Button
                                        variant='contained'
                                        onClick={handleLoginWithSSO}
                                        sx={{
                                            height: 50,
                                            width: 200,
                                            alignItems: 'center'
                                        }}>
                                        Login With SSO
                                    </Button>
                                }
                            </Box>
                        </div>
                    )
                }
            </Box>
        </Container>
    )
}

export default LoginPage;
