import CryptoJS from 'crypto-js';
import envConfig from '../../../environments/index';
import initiateS3Client from '../../config/s3Client';

const URL_EXPIRATION_SECONDS = 300;

export const getDiagramSignedUploadUrl = async function (filename, metadata, operation) {
    const sortedValues = Object.keys(metadata)
        .sort()
        .map(key => metadata[key])
        .join();
    const uuid = CryptoJS.SHA1(sortedValues).toString();
    const params = {
        Bucket: envConfig.WIRING_DIAGRAM_BUCKET,
        Key: `${filename}_${uuid}.svg`,
        Expires: URL_EXPIRATION_SECONDS,
        ContentType: 'image/svg+xml',
        Metadata: metadata,
    };
    const s3 = await initiateS3Client()
    return await s3.getSignedUrlPromise(operation, params);
};

export const keyExistsBy = async function (filename, metadata) {
    const sortedValues = Object.keys(metadata)
        .sort()
        .map(key => metadata[key])
        .join();
    const uuid = CryptoJS.SHA1(sortedValues).toString();
    const key = `${filename}_${uuid}.svg`;
    const headParams = {
        Bucket: envConfig.WIRING_DIAGRAM_BUCKET,
        Key: key
    };
    try {
        const s3 = await initiateS3Client()
        await s3.headObject(headParams).promise();
        return true;
    } catch (error) {
        if (error.statusCode === 404) {
            return false;
        } else {
            console.log('Error', error);
            throw error;
        }
    }
};
