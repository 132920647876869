import { useState, useEffect } from 'react';
import { Cancel } from '@mui/icons-material';
import { Checkbox, TextField, createFilterOptions, FormControlLabel } from '@mui/material';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import StyledAutocomplete from '../CustomAutocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  // uncomment if users want to limit the number of options
  // limit: 100, 
  stringify: (option) => option,
});



const sortInsensitiveCase = (dataList) => {
  return dataList.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  })
}

export default function MultipleSelectChip(props) {
  const { id, labelId, label } = props;
  const { selectedValues, setSelectedValues } = props;
  const { conditionKey, targetParam } = props;
  const { fetchData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [tempSelectedValues, setTempSelectedValues] = useState(selectedValues || []);
  const [inputTarget, setInputTarget] = useState('');

  // Dependant filter options
  const [dynamicValues, setDynamicValues] = useState([]);

  useEffect(() => {
    setTempSelectedValues(selectedValues)
  }, [selectedValues])

  const handleChange = (event, value) => {
    setSelectedValues(conditionKey, []);  // reset current
    if (value.length === 0) {
      return
    }
    setSelectedValues(
      conditionKey, 
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const onDelete = (value) => {
    const newData = tempSelectedValues.filter(item => item !== value)
    setTempSelectedValues(newData)
    setSelectedValues(conditionKey, newData)
  }

  const onClose = () => {
    setSelectedValues(conditionKey, tempSelectedValues)
  };

  const reload = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      setDynamicValues(sortInsensitiveCase(await fetchData(targetParam)));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <StyledAutocomplete
      clear
      loading={isLoading}
      disableCloseOnSelect
      data-testid={labelId}
      labelId={labelId}
      id={id}
      sx={{
        width: 400,
        overflowY: 'hidden'
      }}
      multiple
      value={tempSelectedValues}
      onChange={handleChange}
      onClose={onClose}
      onClick={reload}
      inputValue={inputTarget}
      onInputChange={(event, newValue, reason) => {
        if (reason !== 'reset') {
          setInputTarget(newValue)
        }
      }}
      ChipProps={{
        color: 'primary',
        variant: 'outlined',
        sx: [{
          display: 'flex',
          overflow: 'hidden',
          overflowX: 'auto',
          columnGap: 0.5
        }]
      }}
      renderTags={(selected, getTagProps) =>
        selected.map((value, index) =>
          <Chip
            color='primary'
            variant='outlined'
            key={value}
            label={value}
            clickable
            onDelete={() => onDelete(value)}
            {...getTagProps({ index })}
          />
        )
      }
      limitTags={2}
      renderOption={(props, option) => {
        return (
          <Box
            data-testid='select-option-id'
            component='li'
            key={`${option}`}
            {...props}
          >
            <FormControlLabel
              value='end'
              control={<Checkbox checked={tempSelectedValues.includes(option)} />}
              labelPlacement='end'
              label={null}
            />
            {`${option}`}
          </Box>
        )
      }}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option}
      options={dynamicValues}
      renderInput={(params) => {
        return <TextField
          {...params}
          label={label} placeholder={label} />
      }}

    />
  );
}
