import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Paper } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DIAGRAM_COMPARISON_PATH, COMPARISON_STATUS_VALUES, GLOBAL_WIDTH_OFFSET } from '../../constants';
import { UserContext } from '../App/appContext';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import envConfig from '../../../environments';
import ComparisonDataGrid from '../../components/DataGrid/comparisonDataGrid';
import SearchBar from '../../components/SearchBar';
import DiagramPicker from '../DiagramPickerPage';
import ComparisonResultPage from '../ComparisonResultPage';
import ComparisonDeletePopUp from '../../components/Dialog/comparisonDeleteDialog';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';

import '../../../styles/appStyle.css'
import { getAccessToken } from '../LoginPage/auth';


export default function ComparisonListPage() {
    const { currUser, setCurrUser } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [snackbar, setSnackbar] = useState(null);
    const defaultConditions = { isCreatedByMe: false }
    const [conditions, setConditions] = useState(defaultConditions)
    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    const [dialogOpen, setDialogOpen] = useState(false);
    const [rerender, setRerender] = useState(false);
    const comparisonBasePath = `${envConfig.BACKEND_SERVER}/${DIAGRAM_COMPARISON_PATH}`
    const scrollbarWidth = getScrollbarWidth();

    useResizeHandler(setContainerDimensions);

    useEffect(() => {
        fetchData();
    }, [rerender, currUser, conditions, location.key]);

    const getUrlSearchParams = () => {
        const params = new URLSearchParams()
        if (conditions && conditions.isCreatedByMe) {
            params.append('author', currUser.username)
        }
        if (searchQuery && searchQuery !== '') {
            params.append('q', searchQuery);
        }
        // TODO: Remove when v2 is official
        params.append('version', '2');
        return params
    }
    const compareDatetimeStrings = (a, b) => {
        if ((!a && !a?.created_at) || (!b && !b?.created_at))
            return a?.diagramName > b?.diagramName;
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
    };

    const fetchData = async () => {
        const conditionParams = getUrlSearchParams();
        setIsLoading(true);
        try {
            if (!currUser) return;
            const accessToken = await getAccessToken(currUser, setCurrUser)
            const res = await fetch(`${comparisonBasePath}?${conditionParams.toString()}`, {
                method: 'GET',
                headers: { 'auth': accessToken },
            });
            const data_res = await res.json();
            const newRows = data_res
                .sort(compareDatetimeStrings)
                .map((data, index) => {
                    const comparatorParts = data.comparator.split('$');
                    const comparatorValue = comparatorParts[1];
                    const comparateeList = data.comparatee_list.map((item) => {
                        const parts = item.split('$');
                        return parts[1];
                    });
                    const note = data?.note || '';
                    const status = data?.status || COMPARISON_STATUS_VALUES.PENDING;
                    const createdAt = data?.created_at || '';
                    return {
                        index: index + 1,
                        id: data.id,
                        comparator: comparatorValue,
                        comparateeList: comparateeList,
                        author: data.author,
                        note: note,
                        status: status,
                        createdAt: createdAt,
                    };
                });
            setIsLoading(false);
            setRows(newRows);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const addCompareRecord = () => {
        let currentPath = `${location.pathname}`
        if (currentPath === '' || currentPath === '/') {
            currentPath = '/compare-diagram'
        }
        navigate(`${currentPath}/compare`)
    }

    const onCreatedByMeChange = () => {
        setConditions({
            ...conditions,
            isCreatedByMe: !conditions.isCreatedByMe
        })
    }

    const handleRowSelection = (row) => {
        setSelectedRows(row);
    }

    const deleteSelectedRow = () => {
        return new Promise((resolve, reject) => {
            let status = null;
            const ids = selectedRows.map((r) => r.id).join(',');
            const params = new URLSearchParams({ ids: ids, version: '2' });
            const accessToken = getAccessToken(currUser)
            accessToken.then((token) => {
                fetch(`${comparisonBasePath}?${params.toString()}`, {
                    method: 'DELETE',
                    headers: { 'auth': token }
                }).then((resp) => {
                    status = resp.status;
                    if (status !== 204) {
                        reject(setSnackbar({ children: `Server error with status ${status}`, severity: 'error' }));
                    } else {
                        resolve(setSnackbar({ children: 'Successfully deleted', severity: 'success' }));
                        fetchData();
                    }
                }).catch((err) => {
                    reject(setSnackbar({ children: `Server error: ${err}`, severity: 'error' }));
                });
            });
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateCurrentRow = (row) => {
        return new Promise((resolve, reject) => {
            let status = null;
            const params = new URLSearchParams({ id: row.id, note: row?.note });
            const accessToken = getAccessToken(currUser, setCurrUser)
            accessToken.then((token) => {
                fetch(`${comparisonBasePath}?${params.toString()}`, {
                    method: 'PUT',
                    headers: { 'auth': token }
                }).then((resp) => {
                    status = resp.status;
                    if (status !== 204) {
                        reject(setSnackbar({ children: `Server error with status ${status}`, severity: 'error' }));
                    } else {
                        resolve({ ...row });
                    }
                }).catch((err) => {
                    reject(new Error(`Server error: ${err}`));
                });
            });
        })
    };

    const processRowUpdate = useCallback(
        async (newRow) => {
            const response = await updateCurrentRow(newRow);
            setSnackbar({ children: 'Successfully saved', severity: 'success' });
            return response;
        },
        [updateCurrentRow],
    );

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleCloseSnackbar = () => setSnackbar(null);

    const renderPopup = () => {
        return (
            <ComparisonDeletePopUp
                isOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                deleteSelectedRow={deleteSelectedRow}
            />
        )
    }

    const renderResultPage = () => {
        return (
            <Box
                sx={{ bgcolor: 'lightgray' }}
                height={containerDimensions.height}
                width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
            >
                <Box
                    className='container'
                    maxWidth
                    sx={{ my: 0.5, px: 0.5, pb: 0.5, pt: 0.5 }}
                    justifyContent={'space-between'}
                    display={'flex'}
                >
                    <Paper sx={{
                        height: 75,
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,
                        px: 1,
                    }}>
                        <SearchBar
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            fetchData={fetchData}
                        />
                        <Box sx={{ ml: 1 }}>
                            <IconButton
                                size='medium'
                                sx={{
                                    borderRadius: 3,
                                    fontSize: 'inherit',
                                    justifyContent: 'space-between',
                                    width: 100
                                }}
                                onClick={addCompareRecord}>
                                <AddOutlinedIcon sx={{ mr: 0.5 }} />
                                Add new
                            </IconButton>
                        </Box>
                        <FormControlLabel control={
                            <Checkbox
                                checked={conditions.isCreatedByMe}
                                onChange={() => onCreatedByMeChange()}
                                aria-label=''
                            />
                        }
                            label='Created by me'
                        />
                        {conditions.isCreatedByMe && selectedRows.length > 0 &&
                            <Box sx={{ marginLeft: 'auto' }}>
                                <IconButton
                                    size='large'
                                    sx={{
                                        borderRadius: 3,
                                        fontSize: 'inherit',
                                        justifyContent: 'center',
                                        width: 90
                                    }}
                                    onClick={() => setDialogOpen(true)}
                                >
                                    Delete
                                </IconButton>
                            </Box>
                        }


                    </Paper>
                </Box>
                <Box className='container'
                    maxWidth
                    maxHeight
                    sx={{ my: 0.5, px: 0.5, pb: 0.5 }}>
                    <Paper
                        sx={{
                            minHeight: 400,
                            width: '100%',
                            alignItems: 'flex-start',
                            display: 'flex',
                            p: 1
                        }}
                    >
                        <ComparisonDataGrid
                            rows={rows}
                            isLoading={isLoading}
                            showCheckboxSelection={conditions.isCreatedByMe}
                            onRowSelectionChange={handleRowSelection}
                            snackbar={snackbar}
                            handleCloseSnackbar={handleCloseSnackbar}
                            processRowUpdate={processRowUpdate}
                            handleProcessRowUpdateError={handleProcessRowUpdateError}
                        />
                    </Paper>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: 'lightgray' }} height='100%' width='100%'>
            {
                renderPopup()
            }
            <Routes>
                <Route path='' element={renderResultPage()} />
                <Route path='compare' element={<DiagramPicker />} />
                <Route path='comparison-result' 
                       element={
                            <ComparisonResultPage 
                                setRerender={setRerender} 
                                rerender={rerender} 
                            />
                       }
                />
            </Routes>
        </Box>
    );
}
