import { useState } from 'react';
import { Box } from '@mui/material';
import { GLOBAL_WIDTH_OFFSET } from '../../constants';

import PropTypes from 'prop-types';
import useResizeHandler from '../../common/windowSizeHandler';


function CustomTabPanel(props) {
    const { sx, ...other } = props;
    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    useResizeHandler(setContainerDimensions);

    return (
        <Box sx={{
            bgcolor: 'lightgray',
            pr: 1,
            ...sx
        }}
            height={containerDimensions.height - 50}
            width={containerDimensions.width - GLOBAL_WIDTH_OFFSET}
            {
            ...other
            }
        />
    );

}

CustomTabPanel.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
export default CustomTabPanel;
