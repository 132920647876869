import envConfig from '../../../environments';
import { DIAGRAM_COMPARISON_PATH } from '../../constants';
import { getAccessToken } from '../LoginPage/auth';

const comparisonBasePath = `${envConfig.BACKEND_SERVER}/${DIAGRAM_COMPARISON_PATH}`

export const updateComparison = (comparisonId, removeIdsStr, currUser, setCurrUser) => {
    return new Promise((resolve, reject) => {
        let status = null;
        let params = new URLSearchParams({ id: comparisonId });
        if (removeIdsStr !== null) {
            params.append('remove_comparatees', removeIdsStr)
        }
        getAccessToken(currUser, setCurrUser).then(
            (token) => {
                fetch(`${comparisonBasePath}?${params.toString()}`, {
                    method: 'PUT',
                    headers: { 'auth': token }
                })
                    .then((resp) => {
                        if (resp.status === 204)
                            resolve({ status: resp.status })
                        return resp.json()
                    })
                    .then(resp => {
                        resolve({ comparisonId: resp?.id })
                    })
                    .catch((err) => {
                        reject({ message: `Server error: ${err}` });
                    });
            });
    })
};