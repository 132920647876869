import { Box, Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveUserPopup from '../../containers/UserManagementPage/RemoveUserPopup';
import { USER_POOL_GROUPS } from '../../containers/UserManagementPage/user';


function GroupTag(props) {
    const { value } = props;
    const isAdmin = value ? value?.includes(USER_POOL_GROUPS?.ADMIN) : false;
    const displayName = isAdmin ? 'Admin' : 'User';

    return (
        <Box sx={{ gap: 1, display: 'flex' }} >
            <Chip
                data-testid={`group-chip-${displayName}`}
                color='primary'
                variant='outlined'
                key={displayName}
                label={displayName}
            />
        </Box>
    )
}


export default function UserDataGrid(props) {
    const { users, loading } = props;
    const { setSelected } = props;
    const { callGraphAPI } = props;
    const [open, setOpen] = useState(false);
    const [removedUser, setRemoveUser] = useState(null);
    const columns =
        [
            {
                field: 'id',
                headerName: 'Username',
                flex: 0.2,
                headerAlign: 'center',
                align: 'left'
            },
            {
                field: 'displayName',
                headerName: 'Display Name',
                flex: 0.2,
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 0.2,
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'userStatus',
                headerName: 'User Status',
                flex: 0.2,
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'groups',
                headerName: 'Groups',
                flex: 0.1,
                headerAlign: 'center',
                align: 'center',
                renderCell: GroupTag
            },
            {
                width: 10,
                headerAlign: 'center',
                align: 'center',
                renderCell: DeleteUserTag,
                sortable: false
            }
        ]

    function DeleteUserTag({ row }) {
        const onClick = () => {
            setOpen(true)
            setRemoveUser(row)
        }
        return (
            <Box>

                <IconButton
                    size='medium'
                    sx={{
                        borderRadius: 5,
                        fontSize: 'inherit',
                        alignItems: 'center',
                        bgcolor: 'inherit',
                        '&:hover': { bgcolor: '#e9eef5' },
                    }}
                    onClick={onClick}
                >
                    <DeleteOutlineOutlinedIcon color='error' />
                </IconButton>
            </Box>
        )

    }

    return (
        <Box sx={{ width: '100%' }}>
            <RemoveUserPopup
                open={open && removedUser !== null}
                onClose={() => {
                    setOpen(false)
                }}
                callGraphAPI={callGraphAPI}
                selected={removedUser}
            />
            <DataGrid
                columns={columns}
                rows={users}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10
                        }
                    }
                }}
                showColumnVerticalBorder
                showCellVerticalBorder
                paginationMode='client'
                pageSizeOptions={[10, 25]}
                disableColumnMenu
                checkboxSelection
                autoHeight
                disableRowSelectionOnClick
                onRowSelectionModelChange={(ids) => {
                    const selectedValues = users.filter(item => ids.includes(item.id))
                    setSelected(selectedValues)
                }}
                isRowSelectable={({ row }) => (row?.userStatus && row?.memberId)}
            />
        </Box>
    )
}
