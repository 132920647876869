import React from 'react';
import MultipleSelectChip from '../MultipleSelectChip';
import envConfig from '../../../environments';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FilterAltOffOutlined, FilterAltOutlined } from '@mui/icons-material';
import { COMPARISON_FILTER_OPTIONS, GET_FILTER_OPTIONS_PATH, URL_DELIMINATOR } from '../../constants';
import { getAccessToken } from '../../containers/LoginPage/auth';

export default class DiagramFilter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hierarchyFilterOptions: props?.defaultConditions,
            filterOptions: [],
            loading: false,
        }
    }

    updateConditions = (key, value) => {
        this.props.setConditions({
            ...this.props.conditions,
            [key]: value
        })
    }

    resetFilter = () => {
        this.props.setConditions(this.props.defaultConditions)
        this.setState({
            hierarchyFilterOptions: this.props.defaultConditions
        })
    }

    fetchData = async (target) => {
        const { groups, brands, models, domainValues, functionValues, engineCodes, status } = this.props.conditions;
        const paramsMap = {
            groups,
            brands,
            models,
            domain_values: domainValues,
            func_values: functionValues,
            engine_codes: engineCodes,
            status: status,
        };

        const params = {};
        for (const key in paramsMap) {
            if ( key !== target ) {
                const values = paramsMap[key];
                if ( values && values.length > 0 ) {
                    params[key] = values.join(URL_DELIMINATOR);
                }
            }
        }
        const queryString = new URLSearchParams(params).toString();

        let values = [];
        try {
            const url = `${envConfig.BACKEND_SERVER}/${GET_FILTER_OPTIONS_PATH}`
            const accessToken = await getAccessToken(this.props.currUser, this.props.setCurrUser)
            const res = await fetch(
                `${url}?success_only=${this.props.successOnly}&version=2&target=${target}&${queryString}`,
                { 
                    method: 'GET',
                    headers: { 
                        'auth': accessToken 
                    },
                },
            );
            const data_res = await res.json();
            values = data_res?.data.map(item => item.value)
        } catch (error) {
            console.error(error);
        }
        return values;
    };

    render() {
        return (
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: this.props.flexEnd ? 'flex-end' : 'flex-start',
                gap: 1,
                my: 1,
                mx: 0.5,
            }}>
                <MultipleSelectChip
                    id='multiple-chip-groups-label'
                    labelId='multiple-chip-groups'
                    label='Groups'
                    selectedValues={this.props.conditions?.groups}
                    conditionKey='groups'
                    targetParam='groups'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                <MultipleSelectChip
                    id='multiple-chip-brands-label'
                    labelId='multiple-chip-brands'
                    label='Brands'
                    selectedValues={this.props.conditions?.brands}
                    conditionKey='brands'
                    targetParam='brands'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                <MultipleSelectChip
                    id='multiple-chip-models-label'
                    labelId='multiple-chip-models'
                    label='Models'
                    selectedValues={this.props.conditions?.models}
                    conditionKey='models'
                    targetParam='models'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                <MultipleSelectChip
                    id='multiple-chip-domain-values-label'
                    labelId='multiple-chip-domain-values'
                    label='Domain Values'
                    selectedValues={this.props.conditions?.domainValues}
                    conditionKey='domainValues'
                    targetParam='domain_values'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                <MultipleSelectChip
                    id='multiple-chip-func-values-label'
                    labelId='multiple-chip-func-values'
                    label='Function Values'
                    selectedValues={this.props.conditions?.functionValues}
                    conditionKey='functionValues'
                    targetParam='func_values'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                <MultipleSelectChip
                    id='multiple-chip-engine-codes-label'
                    labelId='multiple-chip-engine-codes'
                    label='Engine Codes'
                    selectedValues={this.props.conditions?.engineCodes}
                    conditionKey='engineCodes'
                    targetParam='engine_codes'
                    setSelectedValues={this.updateConditions}
                    fetchData={this.fetchData}
                />
                {
                    this.props?.shouldDisplayStatusFilter &&
                    <MultipleSelectChip
                        id='multiple-chip-status-label'
                        labelId='multiple-chip-status'
                        label='Status'
                        selectedValues={this.props.conditions?.status}
                        conditionKey='status'
                        targetParam='status'
                        setSelectedValues={this.updateConditions}
                        fetchData={this.fetchData}
                    />
                }
                {
                    this.props?.shouldDisplayDateFilter &&
                    <FormControl variant='outlined'>
                        <InputLabel id='filter-selection-label'>Date</InputLabel>
                        <Select
                            id='filter-selection'
                            data-testid='filter-selection-id'
                            labelId='filter-selection-label'
                            sx={{ width: 400, height: 55 }}
                            label='Filter'
                            size='medium'
                            value={this.props.conditions?.date}
                            onChange={(e) => this.updateConditions('date', e.target.value)}
                        >
                            {
                                Object.keys(COMPARISON_FILTER_OPTIONS).map((key) => {
                                    return (
                                        <MenuItem
                                            data-testid='select-filter-option-id'
                                            key={key}
                                            value={COMPARISON_FILTER_OPTIONS[key]}>
                                            {COMPARISON_FILTER_OPTIONS[key]}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
                <Box width={400} height={55} display='flex' flexDirection='row' justifyContent='flex-start'>
                    <IconButton
                        size='large'
                        sx={{
                            borderRadius: 5,
                            fontSize: 'inherit',
                            alignItems: 'center',
                            bgcolor: 'inherit',
                            '&:hover': { bgcolor: '#e9eef5' },
                        }}
                        onClick={() => this.props.onFilterClick()}
                    >
                        <FilterAltOutlined color='primary' />
                        <Typography color='primary'>Filter</Typography>
                    </IconButton>
                    <IconButton
                        size='large'
                        sx={{
                            borderRadius: 5,
                            fontSize: 'inherit',
                            alignItems: 'center',
                            bgcolor: 'inherit',
                            '&:hover': { bgcolor: '#e9eef5' },
                        }}
                        onClick={this.resetFilter}
                    >
                        <FilterAltOffOutlined color='primary' />
                        <Typography color='primary'>Reset</Typography>
                    </IconButton>

                </Box>
            </Box>
        )
    }
}
