import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, Chip, DialogActions, Tooltip } from '@mui/material';
import { GROUP_MEMBERS_API } from '../../constants';
import { deleteUsersInUserPool } from './user';

export default class RemoveUserPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: ''
        }
    }

    componentDidMount() {
        this.resetState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.resetState()
        }
    }

    resetState = () => {
        this.setState({
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: ''
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    removeUsers = async () => {
        const removeUser = this.props.selected
        if (this.props.selected === null) {
            this.setState({
                errMsg: 'Remove user is empty'
            })
            return;
        }
        let removeInGroupPromise = []
        let removeInUserPoolPromise = []
        try {
            if (removeUser?.memberId) {
                removeInGroupPromise.push(await this.removeMemberInGroup(removeUser.memberId))
            }
            if (removeUser?.userStatus) {
                removeInUserPoolPromise.push(await this.removeMemberInUserPool(removeUser.id))
            }

            const responses = await Promise.all([...removeInGroupPromise, ...removeInUserPoolPromise])
            for (let response of responses) {
                let sucessMsg = []
                if (response?.$response?.httpResponse?.statusCode === 200) {
                    sucessMsg.push('User removed in UserPool')
                }
                if (response?.status === 204) {
                    sucessMsg.push('User removed in Member Group')
                }
                if (sucessMsg.length !== 0) {
                    this.setState({
                        successMsg: sucessMsg.join(' and ')
                    })
                }
            }
        } catch (err) {
            this.setState({
                errMsg: `Remove ${removeUser.id} got error: ${err?.message}`
            })
        }
    }

    removeMemberInUserPool = async (userId) => {
        return await deleteUsersInUserPool(userId)

    }

    removeMemberInGroup = async (memberId) => {
        return await this.props.callGraphAPI(`${GROUP_MEMBERS_API}/${memberId}/$ref`, 'DELETE')
    }



    render() {
        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <Box
                    sx={{
                        p: 2,
                        minWidth: 250,
                        gap: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {
                        this.state.errMsg !== '' &&
                        <Alert severity='error'>{this.state.errMsg}</Alert>
                    }
                    {
                        this.state.successMsg !== '' &&
                        <Alert severity='success'>{this.state.successMsg}</Alert>
                    }
                    <DialogTitle alignContent='center'>Remove User</DialogTitle>

                    <Box
                        sx={{
                            '& .MuiChip-root': { m: 1, width: '25ch' },
                            gap: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'

                        }}
                    >
                        {
                            this.props.selected ?
                                <Tooltip title={`${this.props.selected?.id}`}>
                                    <Chip
                                        label={`${this.props.selected?.id}`}
                                        color='info'
                                        variant='outlined'

                                    />
                                </Tooltip>
                                : null
                        }

                    </Box>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                backgroundColor: '#aaaaaa',
                                '&:hover': { backgroundColor: '#8c8b8b' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.removeUsers}
                        >
                            Remove
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        );
    }
}
