import { Box, Link, Typography } from '@mui/material';
import logo from './img_diagram.png'
import { getSignedUrlS3Object } from '../../common/getS3Object';
import envConfig from '../../../environments';



export default function DiagramCardWithCaption(props) {
    const { diagramName, imageUri, setErrorMsg } = props
    const { additionalActions } = props || []

    const openImageInNewTab = async () => {
        if (imageUri === null || imageUri === '') {
            setErrorMsg('Cannot open image because the image URI is missing')
            return
        }

        let bucketName = envConfig.WIRING_DIAGRAM_BUCKET;
        if ( imageUri.includes(envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET) ) {
            bucketName = envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET;
        }

        try {
            const pathParts = imageUri.split('/').slice(3);
            const imagePath = pathParts.join('/');
            const url = await getSignedUrlS3Object(imagePath, 4500, bucketName);
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (error) {
            console.error('Failed to retrieve the signed URL:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 40,
                    gap: 1
                }}>
                    {
                        additionalActions && additionalActions.map((action) => action)
                    }
                    {
                        !additionalActions && <Box height={40} />
                    }
                </Box>
                <img style={{ width: 60, height: 60, borderRadius: 10 }} src={logo} />
                <figcaption>
                    <Typography
                        onClick={openImageInNewTab}
                        component={Link} sx={{ fontSize: 12 }}>
                        {diagramName}
                    </Typography>
                </figcaption>
            </Box>
        </Box>
    )
}
