import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
    COMPARISON_FILTER_OPTIONS,
    GLOBAL_WIDTH_OFFSET,
    URL_DELIMINATOR,
} from '../../constants';
import {
    Box,
    Button,
    Paper,
    TextField,
    ThemeProvider,
    Typography,
    createFilterOptions,
    createTheme
} from '@mui/material';

import DiagramPickerDialog from '../../components/Dialog/diagramPickerDialog';
import getScrollbarWidth from '../../common/scrollbarWidth';
import DiagramFilter from '../../components/DiagramFilter';
import InfoDialog from '../../components/Dialog/infoDialog';
import useResizeHandler from '../../common/windowSizeHandler';

import { UserContext } from '../App/appContext';
import { useTheme } from 'styled-components';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import StyledAutocomplete from '../../components/CustomAutocomplete';
import { createComparison, getDiagramForComparison } from './fetchData';
import { v4 as uuidv4 } from 'uuid';

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme?.palette?.mode,
        },
        components: {
            MuiAutocomplete: {
                defaultProps: {
                    renderOption: (props, option, state, ownerState) => {
                        const hasValue = option?.similarity || option?.similarity === 0
                        const similarity = hasValue ?
                            `${(option?.similarity * 100).toFixed(2)}%` : ''
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                data-testid='select-diagram-id'
                                component='li'
                                {...props}
                                key={`${option?.diagram_id}$${option?.diagram_name}`}
                            >
                                {
                                    ownerState?.isComparteeSelection &&
                                    <Typography component='span' sx={{
                                        fontStyle: 'italic',
                                    }}>
                                        {`${similarity}`}
                                    </Typography>

                                }
                                <Typography component='span'>
                                    {`${option?.diagram_name}`}
                                </Typography>

                            </Box>
                        )
                    },
                    filterOptions: filterOptions,
                    getOptionLabel: (option) => option?.diagram_name,
                },
            },
        },
    });

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    // uncomment if users want to limit the number of options
    // limit: 100, 
    stringify: (option) => {
        return option?.diagram_name
    },

});

function DiagramPickerPage() {
    const outerTheme = useTheme();
    const { currUser, setCurrUser } = useContext(UserContext);

    const [originDiagram, setOriginDiagram] = useState(null);
    const [targetDiagrams, setTargetDiagrams] = useState([]);
    const [inputTarget, setInputTarget] = useState('');
    const [ctorPickerLoading, setCtorPickerLoading] = useState(false);
    const [cteePickerLoading, setCteePickerLoading] = useState(false);

    const defaultFilters = {
        groups: [],
        brands: [],
        models: [],
        domainValues: [],
        functionValues: [],
        engineCodes: [],
        status: [],
        date: COMPARISON_FILTER_OPTIONS.ALL,
    }

    const [conditions, setConditions] = useState(defaultFilters);
    const [filteredDiagrams, setFilteredDiagrams] = useState([]);
    const [filteredComparateeDiagrams, setFilteredComparateeDiagrams] = useState([]);

    const [showInfoPopUp, setShowInfoPopUp] = useState(false);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

    const location = useLocation();
    const parentRoute = location.pathname.split('/')?.at(1) || '';
    const scrollbarWidth = getScrollbarWidth();

    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    useResizeHandler(setContainerDimensions);

    const updateDiagramListState = async (
        setListDiagramCallback,
        setFilterDiagramsCallback,
        setLoading,
        comparatorId = null
    ) => {
        setLoading(true);
        const data = await getDiagramForComparison(comparatorId, currUser, setCurrUser);
        setLoading(false);
        setListDiagramCallback(data);
        setFilterDiagramsCallback(data);
    }

    const filterData = async (setLoading, setValue, comparatorId) => {
        setLoading(true);
        let today = new Date();
        let startDate = new Date(today);
        startDate.setHours(0, 0, 0, 0);

        switch (conditions.date || COMPARISON_FILTER_OPTIONS.ALL) {
            case COMPARISON_FILTER_OPTIONS.TODAY:
                startDate = today;
                break
            case COMPARISON_FILTER_OPTIONS.LAST_3_DAYS:
                startDate.setDate(today.getDate() - 3);
                break
            case COMPARISON_FILTER_OPTIONS.LAST_7_DAYS:
                startDate.setDate(today.getDate() - 7);
                break
        }

        today = today.getTime();
        startDate = startDate.getTime();

        const {
            groups,
            models,
            brands,
            domainValues,
            functionValues,
            engineCodes,
        } = conditions;

        const params = new URLSearchParams({
            'groups': groups?.join(URL_DELIMINATOR),
            'models': models?.join(URL_DELIMINATOR),
            'brands': brands?.join(URL_DELIMINATOR),
        });

        if ( conditions.date !== COMPARISON_FILTER_OPTIONS.ALL ) {
            params.append('updated_at', [startDate, today].join(URL_DELIMINATOR))
        }

        // Support filter by empty string by having empty value as -1
        if (domainValues && domainValues.length !== 0) {
            const dValues = domainValues.map(item => (item === '' ? '-1' : item));
            params.append('domain_values', dValues.join(URL_DELIMINATOR))
        }
        if (functionValues && functionValues.length !== 0) {
            const fValues = functionValues.map(item => (item === '' ? '-1' : item));
            params.append('func_values', fValues.join(URL_DELIMINATOR))
        }
        if (engineCodes && engineCodes.length !== 0) {
            const eValues = engineCodes.map(item => (item === '' ? '-1' : item));
            params.append('engine_codes', eValues.join(URL_DELIMINATOR))
        }

        const filteredList = await getDiagramForComparison(comparatorId, currUser, setCurrUser, params);

        await new Promise((resolve) => setTimeout(resolve, 1000)); // await for indicator display
        setValue([...filteredList]);
        setLoading(false);
    }

    const handleCompare = (e) => {
        e.preventDefault();
        const comparateeIdList = targetDiagrams.map((diagram) => {
            return `${diagram?.diagram_id}$${diagram?.diagram_name}`
        }).join(',');
        const comparatorID = `${originDiagram?.diagram_id}$${originDiagram?.diagram_name}`;

        if (comparatorID === '' || comparateeIdList === '') {
            setShowInfoPopUp(true);
            return;
        }

        createComparison(uuidv4(), comparatorID, comparateeIdList, currUser, setCurrUser)
            .then(result => {
                if (result) {
                    setShowSuccessPopUp(true)
                }
            }).catch(err => console.log(err))
    }

    const onFilterClick = () => {
        filterData(setCtorPickerLoading, setFilteredDiagrams, null);
    }

    return (
        <ThemeProvider theme={customTheme(outerTheme)}>
            <Box
                sx={{ bgcolor: 'lightgray' }}
                height={containerDimensions.height}
                width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridAutoColumns: '1fr',
                        height: '100%',
                        p: 1,
                        m: 0.5,
                        mt: 1,
                    }}
                    component={Paper}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: 1,
                            gridRow: '1',
                            gridColumn: 'span 2',
                            my: 1,
                        }}
                    >
                        <StyledAutocomplete
                            data-testid='comparator-selection-id'
                            id='comparator-selection'
                            loading={ctorPickerLoading}
                            value={originDiagram}
                            onChange={(event, newValue) => {
                                setOriginDiagram(newValue);
                                filterData(setCteePickerLoading, setFilteredComparateeDiagrams, newValue?.diagram_id);
                            }}
                            options={filteredDiagrams}
                            sx={{ width: 600, height: 55 }}
                            renderInput={(params) => <TextField {...params} label='Comparator' />}
                        />
                        <StyledAutocomplete
                            isComparteeSelection={true}
                            loading={cteePickerLoading}
                            disableCloseOnSelect
                            limitTags={3}
                            data-testid='comparatees-selection-id'
                            id='comparatees-selection'
                            multiple
                            value={targetDiagrams}
                            onChange={(event, newValue) => {
                                setTargetDiagrams(newValue);
                            }}
                            inputValue={inputTarget}
                            onInputChange={(event, newValue, reason) => {
                                if (reason !== 'reset') {
                                    setInputTarget(newValue)
                                }
                            }}
                            options={filteredComparateeDiagrams}
                            sx={{ width: 600, height: 55 }}
                            ChipProps={{
                                color: 'primary', variant: 'outlined', sx: [{
                                    display: 'flex',
                                    overflow: 'hidden',
                                    overflowX: 'auto',
                                    columnGap: 0.5
                                }]
                            }}
                            renderInput={(params) => <TextField {...params} label='Comparatee(s)' placeholder='Comparatee(s)' />}
                        />
                        <Button variant='contained' type='submit' onClick={handleCompare}>Compare</Button>
                        <DiagramPickerDialog
                            isOpen={showSuccessPopUp}
                            onClose={() => setShowSuccessPopUp(false)}
                            parentRoute={parentRoute}
                        />
                        <InfoDialog
                            isOpen={showInfoPopUp}
                            onClose={() => setShowInfoPopUp(false)}
                            message='Comparator and Comparatee(s) must neither be empty.'
                        />
                    </Box>
                    <Box sx={{
                        gridRow: '1',
                        gridColumn: 'span 1'
                    }}>
                        <DiagramFilter
                            conditions={conditions}
                            setConditions={setConditions}
                            successOnly={true}
                            flexEnd={true}
                            defaultConditions={defaultFilters}
                            onFilterClick={onFilterClick}
                            shouldDisplayDateFilter={true}
                            shouldDisplayStatusFilter={false}
                            currUser={currUser}
                            setCurrUser={setCurrUser}
                        />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default DiagramPickerPage;
