import { Component } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, Chip, DialogActions, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GROUP_MEMBERS_API, READ_USER_API } from '../../constants';
import { getErrorMessages } from './userManagementException';

export default class AddUserPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: ''
        }
    }

    componentDidMount() {
        this.resetStates()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.resetStates()
        }
    }

    resetStates = () => {
        this.setState({
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: ''
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    onSearchEmail = () => {
        this.getUserByEmail()
    }

    getUserByEmail = async () => {
        const emailSuffix = '@tecalliance.net'
        const fullEmail = this.state.inputEmail.toLowerCase().match('@') ?
            this.state.inputEmail :
            `${this.state.inputEmail}`.concat(emailSuffix)
        await this.props.callGraphAPI(`${READ_USER_API}/${fullEmail}`)
            .then(response => {
                const error = response.error
                if (error) {
                    const errMsg = getErrorMessages(error, fullEmail)
                    this.setState({
                        errMsg: errMsg || '',
                        user: null
                    })
                } else {
                    this.setState({
                        errMsg: '',
                        user: response
                    })
                }
            })
            .catch(err => {
                this.setState({
                    errMsg: err
                })
            })
    }


    addMemberIntoGroup = async () => {
        if (this.state.user && this.state.user?.id) {
            const body = {
                '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${this.state.user?.id}`
            }
            const response = await this.props.callGraphAPI(`${GROUP_MEMBERS_API}/$ref`, 'POST', body)
            if (response && response.status === 204) {
                this.setState({
                    errMsg: '',
                    successMsg: 'User added'
                })
            } else {
                this.setState({
                    errMsg: response?.error?.message
                })
            }
        } else {
            this.setState({
                errMsg: 'User not found'
            })
        }
    }

    render() {
        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <Box
                    sx={{
                        p: 2,
                        minWidth: 250,
                        gap: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {
                        this.state.errMsg !== '' &&
                        <Alert severity='error'>{this.state.errMsg}</Alert>
                    }
                    {
                        this.state.successMsg !== '' &&
                        <Alert severity='success'>{this.state.successMsg}</Alert>
                    }
                    <DialogTitle alignContent='center'>Add User</DialogTitle>

                    <Box
                        sx={{
                            '& .MuiChip-root': { m: 1, width: '20ch' },
                            gap: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'

                        }}
                    >
                        <FormControl>
                            <InputLabel htmlFor='invite-user-with-icon'>Email</InputLabel>
                            <Input id='invite-user-with-icon'
                                sx={{
                                    minWidth: 200
                                }}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                bgcolor: 'inherit',
                                                borderRadius: 5,
                                                fontSize: 'inherit',
                                                alignItems: 'center',
                                                '&:hover': { backgroundColor: 'inherit' }

                                            }}
                                            onClick={this.onSearchEmail}
                                        >
                                            <SearchIcon color='info' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => this.setState({
                                    inputEmail: e.target.value
                                })}
                                components={Chip}

                            />
                        </FormControl>
                        {
                            this.state.user ?
                                <Chip
                                    label={`${this.state.user?.displayName}`}
                                    color='info'
                                    variant='outlined'

                                />
                                : null
                        }

                    </Box>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                backgroundColor: '#aaaaaa',
                                '&:hover': { backgroundColor: '#8c8b8b' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.user ? false : true}
                            sx={{
                                '&:disabled': {
                                    color: 'white',
                                    backgroundColor: '#aaaaaa'
                                }
                            }}
                            onClick={this.addMemberIntoGroup}
                        >
                            Invite
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        );
    }
}
