import envConfig from '../../environments/index'
import initiateS3Client from '../config/s3Client';

const URL_EXPIRATION_SECONDS = 300

export const getSignedUrlS3Object = async function (
    s3Uri,
    expirationTime = URL_EXPIRATION_SECONDS,
    s3Bucket = envConfig.WIRING_DIAGRAM_BUCKET,
) {
    const params = {
        Bucket: s3Bucket,
        Key: s3Uri,
        Expires: expirationTime,
    };
    const s3 = await initiateS3Client()
    return await s3.getSignedUrlPromise('getObject', params);
}
