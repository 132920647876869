import { useEffect } from 'react';


const useResizeHandler = (setContainerDimensions) => {
    useEffect(() => {
        function handleResize() {
            setContainerDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setContainerDimensions]);
}

export default useResizeHandler;
