import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../App/appContext';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';
import { GLOBAL_WIDTH_OFFSET } from '../../constants';
import { Paper } from '@mui/material';


const UserProfile = () => {
    const { currUser } = useContext(UserContext);
    const scrollbarWidth = getScrollbarWidth();
    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useResizeHandler(setContainerDimensions);

    useEffect(() => {
    }, [currUser])

    return (
        <Box sx={{ bgcolor: 'lightgray' }} height='100%' width='100%'>
            <Box
                sx={{ bgcolor: 'lightgray' }}
                height={containerDimensions.height}
                width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
            >
                <Box
                    className='container'
                    maxWidth
                    sx={{ my: 0.5, px: 0.5, pb: 0.5, pt: 0.5 }}
                    justifyContent={'space-between'}
                    display={'flex'}
                >
                    <Paper sx={{
                        height: 75,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        px: 1,
                    }}>
                        <Typography variant='h4' component='h5' sx={{ alignItems: 'center' }}>
                            User Profile
                        </Typography>
                    </Paper>
                </Box>
                <Box
                    sx={{
                        '& .MuiTextField-root': { width: '50ch', border: 1, borderRadius: 2 },
                        '& .MuiTextField-root .Mui-disabled': { bgcolor: '#FAFAFA' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        my: 0.5,
                        px: 0.5,
                        pb: 0.5,
                    }}
                    component='form'
                >
                    <Paper
                        sx={{
                            width: '100%',
                            alignItems: 'flex-start',
                            display: 'flex',
                            p: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <TextField
                            id='username'
                            label='Username'
                            variant='filled'
                            disabled
                            defaultValue={currUser?.username}
                        />
                        <TextField
                            id='email'
                            label='Email'
                            variant='filled'
                            disabled
                            defaultValue={currUser?.email}
                        />
                        <TextField
                            id='email'
                            label='Email Verified'
                            variant='filled'
                            disabled
                            defaultValue={currUser?.emailVerified ? 'Yes' : 'No'}
                        />
                        <FormControl>
                            <FormLabel id='demo-row-radio-buttons-group-label'>Groups</FormLabel>
                            <Box
                                sx={{
                                    '& .MuiChip-root': { m: 1, width: '25ch' },
                                    border: 1,
                                    borderRadius: 2,
                                    gap: 1,
                                    width: '50ch',
                                }}
                            >
                                {
                                    currUser?.groups.map(group => {
                                        return (
                                            <Tooltip title={group}>
                                                <Chip label={group} />
                                            </Tooltip>
                                        )
                                    })
                                }

                            </Box>
                        </FormControl>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}

export default UserProfile;
