import {
    Link,
    Route,
    matchPath,
    useLocation,
    Routes,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomePage from '../../containers/HomePage';
import DiagramPicker from '../../containers/DiagramPickerPage';
import UploadPage from '../../containers/UploadPage';
import WiringDiagramPage from '../../containers/WiringDiagramPage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CompareIcon from '@mui/icons-material/Compare';
import ComparisonListPage from '../../containers/ComparisonListPage';
import ComparisonResultPage from '../../containers/ComparisonResultPage';
import UserProfile from '../../containers/UserProfile';
import UserManagementPage from '../../containers/UserManagementPage';
import { DocumentScanner, ManageAccounts } from '@mui/icons-material';
import { isAdminUser } from '../../containers/UserManagementPage/user';
import { useContext } from 'react';
import { UserContext } from '../../containers/App/appContext';
import DiagramMissingInfoPage from '../../containers/DiagramMissingInfoPage';
import APIDocumentation from '../../containers/APIDocumentation';
import envConfig from '../../../environments';

function useRouteMatch(patterns) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

function VerticalTabs({ isAdmin }) {
    const defaultRoute = ['/wiring-diagram/*', '/compare-diagram/*', '/api-documentation'];
    const routes = isAdmin ? ['/user-management'].concat(defaultRoute) : defaultRoute;
    const routeMatch = useRouteMatch(routes);
    const currentTab = (routeMatch && routeMatch?.pattern?.path) || '/wiring-diagram/*';
    const showAPIDoc = envConfig.SHOW_API_DOCUMENTATION;
    return (
        <Tabs
            orientation='vertical'
            sx={{
                borderRight: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'flex-start',
                minWidth: 200,
            }}
            value={currentTab}
        >
            <Tab icon={<AccountTreeIcon />}
                sx={{ justifyContent: 'flex-start' }}
                iconPosition='start'
                label='Wiring Diagrams'
                value='/wiring-diagram/*'
                to='/wiring-diagram'
                component={Link}
            />
            <Tab icon={<CompareIcon />}
                sx={{ justifyContent: 'flex-start' }}
                iconPosition='start'
                label='Diagram Comparison'
                value='/compare-diagram/*'
                to='/compare-diagram'
                component={Link}
            />
            {
                showAPIDoc && 
                <Tab icon={<DocumentScanner />}
                    sx={{ justifyContent: 'flex-start' }}
                    iconPosition='start'
                    label='API Documentation'
                    value='/api-documentation'
                    to='/api-documentation'
                    component={Link}
                />
            }
            {
                isAdmin &&
                <Tab icon={<ManageAccounts />}
                    sx={{ justifyContent: 'flex-start' }}
                    iconPosition='start'
                    label='User Management'
                    value='/user-management/*'
                    to='/user-management'
                    component={Link}
                />
            }
        </Tabs>
    );
}

export default function TabsRouter() {
    const { currUser } = useContext(UserContext);
    const isAdmin = isAdminUser(currUser);
    return (
        <Box sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100vh',
            width: '100vw',
        }}>
            <VerticalTabs isAdmin={isAdmin || false} />
            <Routes>
                <Route exact path='/' element={<HomePage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/wiring-diagram/*' element={<WiringDiagramPage />}>
                    <Route path='upload' element={<UploadPage />} />
                    <Route path='diagram/:diagramId' element={<DiagramMissingInfoPage />} />
                </Route>
                <Route path='/compare-diagram/*' element={<ComparisonListPage />}>
                    <Route path='compare' element={<DiagramPicker />} />
                    <Route path='comparison-result' element={<ComparisonResultPage />} />
                </Route>
                <Route path='/user-profile/*' element={<UserProfile />} />
                <Route path='/user-management/*' element={<UserManagementPage />} />
                <Route path='/api-documentation' element={<APIDocumentation />} /> 
            </Routes>
        </Box>
    );
}
