import { tokenize, markEdits } from 'react-diff-view';

export default (hunks) => {
    if (!hunks) {
        return undefined;
    }

    const options = {
        highlight: false,
        enhancers: [markEdits(hunks, { type: 'line' })],
    };

    try {
        const result = tokenize(hunks, options);
        return result
    } catch (ex) {
        return undefined;
    }
};
