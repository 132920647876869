import { S3 } from "aws-sdk"
import envConfig from "../../environments"
import { getCredentials } from "../containers/LoginPage/auth"

const initiateS3Client = async () => {
    const credentials = await getCredentials()
    
    return new S3({
        region: envConfig.REGION,
        credentials: credentials,
        signatureVersion: 'v4'
    })
}

export default initiateS3Client