import { Grid, TextField, } from '@mui/material';
import { useState } from 'react';

const MissingComponentCard = (props) => {
    const { index, item, onChange } = props

    const [componentName, setComponentName] = useState(item?.editted_component_name || item?.component_name || '')
    let typingTimer;

    return (
        <Grid container
            sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 1
            }}
        >

            <Grid item xs={2}>
                <TextField
                    id='component-name-label-id'
                    label='Component Name'
                    variant='outlined'
                    defaultValue={componentName}
                    onChange={(e) => {
                        e.preventDefault()
                        setComponentName(e.target.value)
                    }}
                    onKeyUp={() => {
                        clearTimeout(typingTimer)
                        typingTimer = setTimeout(() => onChange(componentName, index), 1000)
                    }}
                    onKeyDown={() => {
                        clearTimeout(typingTimer)
                    }}
                />
            </Grid>

            <Grid item xs={2} sx={{ ml: 0.5 }}>
                <TextField
                    id='component-type-label-id'
                    label='Component Type'
                    variant='outlined'
                    defaultValue={item?.type}
                    disabled
                />
            </Grid>
        </Grid>
    )
}

export default MissingComponentCard;