import {
    Box,
    IconButton,
    Link,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Alert,
    AlertTitle,
    ListItem,
    List,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DIAGRAM_COMPARISON_PATH, GLOBAL_WIDTH_OFFSET } from '../../constants';
import { useLocation } from 'react-router-dom';
import { AddOutlined, ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';

import ComparisonDetailTreeView from '../../components/ComparisonDetailTreeView';
import DiagramCardWithCaption from '../../components/DiagramCardWithCaption';
import useMessageWithTimeout from '../../common/messageWithTimeoutGenerator';
import envConfig from '../../../environments';

import './index.css';
import { UserContext } from '../App/appContext';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledIconButton from '../../components/StyledIconButton';
import { updateComparison } from './comparison';
import AddComparisonDialog from './AddComparisonDialog';
import DiagramPickerDialog from '../../components/Dialog/diagramPickerDialog';
import { isObjectEmptyOrNull } from '../../common/utils';
import { getAccessToken } from '../LoginPage/auth';

const ComparisonResultPage = (props) => {
    const [comparator, setComparator] = useState({})
    const [comparateeList, setComparateeList] = useState([])
    const [visibilityComparatees, setVisibilityComparatees] = useState([])

    const [comparatee, setComparatee] = useState({})
    const { currUser, setCurrUser } = useContext(UserContext);

    const [data, setData] = useState({});
    const location = useLocation();
    const parentRoute = location?.pathname?.split('/')?.at(1) || '';
    const { dataFromDiagramPicker, author } = location.state || {};
    const [comparisonId, setComparisonId] = useState(dataFromDiagramPicker)
    const [displayData, setDisplayData] = useState({})
    const [isCommponentAsc, setIsComponentAsc] = useState(true)
    const [isSimilarityAsc, setIsSimilarityAsc] = useState(false)
    const [invisibilitySet, setInvisibilitySet] = useState(new Map())
    const [removeComparatees, setRemoveComparatees] = useState(new Map())
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [addComparisonOpen, setAddComparisonOpen] = useState(false)
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

    const [successMsg, setSuccessMsg] = useMessageWithTimeout('', 5000);
    const [errorMsg, setErrorMsg] = useMessageWithTimeout('', 5000);
    const [loadingMsg, setLoadingMsg] = useState('Loading comparison result');
    const [rerender, setRerender] = useState(false)

    const shouldDisplayHideTab = invisibilitySet && invisibilitySet.size !== 0
    const shouldDisplayRemoveTab = removeComparatees && removeComparatees.size !== 0
    const scrollbarWidth = getScrollbarWidth();

    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    const height = containerDimensions.height
    const width = containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET
    useResizeHandler(setContainerDimensions);

    useEffect(() => {
        if (!currUser) return
        try {
            getAccessToken(currUser, setCurrUser).then((token) => {
                fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_COMPARISON_PATH}/${comparisonId.toString()}`, {
                    method: 'GET',
                    headers: { 'auth': token },
                })
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(`Network response was not ok with status ${res.status}`);
                        }
                        return res.json()
                    })
                    .then((data_res) => {
                        if (data_res) {
                            setData(data_res);
                            setLoadingMsg('');
                            setSuccessMsg('Finished loading');
                        }
                    })
                    .catch((error) => {
                        setLoadingMsg('');
                        setErrorMsg(error);
                    });
            }).catch(err => {
                console.log(err)
            });

        } catch (error) {
            console.log(error)
        }
    }, [currUser, rerender, comparisonId]);

    useEffect(() => {
        if (data && data.comparator && data.comparatee_list) {
            setComparator(data.comparator)
            const sortedComparatees = sortBySimilarity(data.comparatee_list)
            setComparateeList(sortedComparatees)
            setVisibilityComparatees(sortedComparatees)
        }
    }, [data])

    useEffect(() => {
        getDifferentComponents()
    }, [comparator, comparatee])

    useEffect(() => {
        if (invisibilitySet.size === 0) {
            setVisibilityComparatees(comparateeList)
        } else {
            const newList = comparateeList.filter(item => !invisibilitySet?.has(item?.diagram_id))
            setVisibilityComparatees(newList)
        }
    }, [invisibilitySet])

    const getDifferentComponents = () => {
        if (isObjectEmptyOrNull(data)) return;
        const components = data.components;

        let displayData = {}
        if (data.comparator != null && data.comparatee_list != null) {
            components.forEach(component => {
                component.connectors.forEach(connector => {
                    connector.pins.forEach(pin => {
                        pin.attached_lines.forEach(line => {
                            const lineNameParts = line.line_name.split('.');
                            const lineName = lineNameParts.slice(0, -1).join('.');
                            const harness = lineNameParts.slice(-1)[0];
                            const value = [
                                `${connector.connector_name}`,
                                `${pin.pin_index}`,
                                `${lineName}`,
                                `${harness}`,
                                `${line.soldering_points}`,
                                `${line.plugged_points}`,
                            ];

                            let componentName = component.component_name
                            line.exist_in.forEach(id => {
                                let prevValue = displayData?.[id]?.[componentName] || []
                                prevValue.push(value)
                                displayData = {
                                    ...displayData,
                                    [id]: {
                                        ...displayData[id],
                                        [componentName]: prevValue
                                    }
                                }
                            })
                        })
                    })
                })
            })
        }
        setDisplayData(displayData)
    }

    useEffect(() => {
        setVisibilityComparatees(sortBySimilarity(visibilityComparatees))
    }, [isSimilarityAsc])

    const sortBySimilarity = (cteeList) => {
        const sortedValue = cteeList.sort(function (ctee1, ctee2) {
            const sortValue = ctee1?.similarity - ctee2?.similarity
            return isSimilarityAsc ? sortValue : -1 * sortValue
        })
        return [...sortedValue]
    }

    const renderSortButton = (sortState, setStortState) => {
        return (
            <Tooltip title={sortState ? 'A-Z' : 'Z-A'}>
                <IconButton
                    aria-label='fingerprint'
                    color='primary'
                    size='small'
                    sx={{
                        bgcolor: 'inherit',
                        color: 'blue',
                        '&:hover': {
                            backgroundColor: 'white'
                        }
                    }}
                    onClick={() => setStortState(!sortState)}
                >
                    {
                        sortState &&
                        <ArrowDownwardOutlined color='blue' />
                    }
                    {
                        !sortState &&
                        <ArrowUpwardOutlined color='blue' />
                    }
                </IconButton>
            </Tooltip>
        )
    }

    const renderDetailsTable = ( {tecaData} ) => {
        return (
            <TableContainer
                data-testid='diagram-details-table-id'
                component={Paper}
                sx={{ mt: 1, borderRadius: 3 }}
            >
                <Table sx={{ overflowX: 'hidden' }}>
                    <TableHead>
                        <TableRow >
                            <TableCell
                                variant='head'
                                style={{
                                    verticalAlign: 'top',
                                    direction: 'row',
                                    width: 200
                                }} />
                            <TableCell>
                                <Box sx={{ justifyContent: 'space-around', display: 'flex', flexDirection: 'row' }}>
                                    <Box marginLeft={'0.5cm'} width='45%'>
                                        <Typography>{comparator?.diagram_name || 'Comparator'}</Typography>

                                    </Box>
                                    <Box width='65%'>
                                        <Typography>{comparatee?.diagram_name || ''}</Typography>

                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableCell variant='head' style={{ verticalAlign: 'top', direction: 'row', width: 200 }}>
                        Components
                        {
                            renderSortButton(isCommponentAsc, setIsComponentAsc)
                        }
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                        <ComparisonDetailTreeView
                            diagramId={comparator?.diagram_id}
                            isAscending={isCommponentAsc}
                            comparator={displayData?.[comparator?.diagram_id]}
                            comparatee={displayData?.[comparatee?.diagram_id]}
                            tecaData={tecaData}
                        />
                    </TableCell>
                </Table>

            </TableContainer>
        )
    }


    const handleDisplayedDetails = (selected) => {
        let value = selected?.diagram_id === comparatee?.diagram_id ? {} : selected
        setComparatee(value)
    }

    const renderAlert = () => {
        return (
            <Box>
                {
                    errorMsg !== '' &&
                    <Alert severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }
                {
                    successMsg !== '' &&
                    <Alert severity='success'>
                        <AlertTitle>Success</AlertTitle>
                        {successMsg}
                    </Alert>
                }
                {
                    loadingMsg !== '' &&
                    <Alert severity='info'>
                        <AlertTitle>Info</AlertTitle>
                        {loadingMsg}
                    </Alert>
                }
            </Box>
        )
    }

    const isSelected = (item) => {
        if (comparatee && item) {
            return item?.diagram_id === comparatee?.diagram_id
        }
        return false
    }

    const onVisibilityUpdate = (ctee, state, setState) => {
        let newInvisibilityMap = new Map(state)
        if (newInvisibilityMap && newInvisibilityMap.has(ctee?.diagram_id)) {
            newInvisibilityMap.delete(ctee?.diagram_id)
        } else {
            newInvisibilityMap.set(ctee?.diagram_id, ctee)
        }
        setState(newInvisibilityMap)
    }

    const onDeleteComparatees = async () => {
        let removeIdsStr = removeComparatees ? Array.from(removeComparatees.keys()).sort().join(',') : null
        updateComparison(comparisonId, removeIdsStr, currUser, setCurrUser)
            .then(resp => {
                if (!resp?.comparisonId || resp?.comparisonId === 'null') {
                    window.history.back()
                    if (props?.setRerender) {
                        props.setRerender(!props?.rerender)
                    }
                } else if (resp?.comparisonId) {
                    setComparisonId(resp?.comparisonId)
                    setRemoveComparatees(new Map())
                }
            }).catch(err => {
                setErrorMsg(err?.message)
            }).finally(() => {
                setConfirmationOpen(false)
            })
    }

    const renderVisibilityTab = (title, state, setState) => {
        return (
            <List sx={{
                width: '100%',
                height: '100%'
            }}>
                <Typography variant='h5'>
                    {title}
                </Typography>
                {
                    Array.from(state).map(([key, value]) => (
                        <ListItem>
                            <Typography
                                component={Chip}
                                key={key}
                                onClick={() => onVisibilityUpdate(
                                    value,
                                    state,
                                    setState
                                )}
                                color='primary'
                                sx={{
                                    bgcolor: 'inherit',
                                    border: 1,
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                label={value?.diagram_name}
                            >
                            </Typography>

                        </ListItem>
                    ))}
            </List>
        )
    }

    const renderDeleteConfirmationPopup = () => {
        return (
            <Dialog onClose={() => setConfirmationOpen(false)} open={confirmationOpen}>
                <DialogTitle>Delete</DialogTitle>
                <DialogContent>
                    Would you like to delete those compartee(s)?
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            backgroundColor: '#aaaaaa',
                            '&:hover': { backgroundColor: '#8c8b8b' }
                        }}
                        onClick={() => setConfirmationOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={onDeleteComparatees}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderAddNewComparison = () => {
        const diagrams = [
            ...comparateeList,
            ...(Object.keys(comparator).length > 0 ? [comparator] : [])
        ] || []

        return (
            <AddComparisonDialog
                diagrams={diagrams}
                open={addComparisonOpen}
                setOpen={setAddComparisonOpen}
                currUser={currUser}
                setCurrUser={setCurrUser}
                setShowSuccessPopUp={setShowSuccessPopUp}
            />
        )
    }

    return (
        <Box
            height={height}
            width={width}
        >
            {
                renderAlert()
            }
            {
                renderDeleteConfirmationPopup()
            }
            {
                renderAddNewComparison()
            }
            <DiagramPickerDialog
                isOpen={showSuccessPopUp}
                onClose={() => setShowSuccessPopUp(false)}
                parentRoute={parentRoute}
            />
            <Grid container
                sx={{
                    display: 'flex',
                    mb: 1
                }}
            >
                <Grid item xs={(shouldDisplayHideTab || shouldDisplayRemoveTab) ? 10 : 12}>
                    <Paper
                        sx={{
                            height: height,
                            overflowY: 'auto',
                            position: 'relative',
                        }}
                    >
                        <TableContainer
                            data-testid='diagram-info-table-id'
                            component={Paper}
                            sx={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                            }}
                        >
                            <Table sx={{ overflowX: 'hidden' }}>
                                <TableRow sx={{ width: '100%' }}>
                                    <TableCell sx={{ width: 400 }} variant='head'>
                                        <Button
                                            variant='outlined'
                                            startIcon={<AddOutlined />}
                                            onClick={() => setAddComparisonOpen(true)}
                                        >
                                            Add New
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ width: 400 }}>
                                        <DiagramCardWithCaption
                                            diagramName={comparator.diagram_name}
                                            imageUri={comparator?.image_uri || ''}
                                            setErrorMsg={setErrorMsg}
                                        />
                                    </TableCell>
                                    {
                                        visibilityComparatees.map((comparatee) => {
                                            return (
                                                <>
                                                    {
                                                        <TableCell
                                                            className={isSelected(comparatee) ? 'highlighted' : ''}
                                                            sx={{ width: 400 }}
                                                        >
                                                            <DiagramCardWithCaption
                                                                diagramName={comparatee.diagram_name}
                                                                imageUri={comparatee?.image_uri || ''}
                                                                setErrorMsg={setErrorMsg}
                                                                additionalActions={[
                                                                    <StyledIconButton
                                                                        data-testid={`hide-btn-id-${comparatee?.diagram_id}`}
                                                                        variant='outlined'
                                                                        onClick={() =>
                                                                            onVisibilityUpdate(
                                                                                comparatee,
                                                                                invisibilitySet,
                                                                                setInvisibilitySet
                                                                            )
                                                                        }
                                                                    >
                                                                        <VisibilityOffIcon />
                                                                    </StyledIconButton>,
                                                                    author === currUser?.username ?
                                                                        <StyledIconButton
                                                                            data-testid={`remove-btn-id-${comparatee?.diagram_id}`}
                                                                            variant='outlined'
                                                                            onClick={() =>
                                                                                onVisibilityUpdate(
                                                                                    comparatee,
                                                                                    removeComparatees,
                                                                                    setRemoveComparatees
                                                                                )}
                                                                        >
                                                                            <DeleteIcon color='error' />
                                                                        </StyledIconButton> : null
                                                                ]}
                                                            />
                                                        </TableCell>
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </TableRow>
                                <TableRow className='row-style1'>
                                    <TableCell variant='head'>Group</TableCell>
                                    <TableCell>{comparator.group}</TableCell>
                                    {
                                        visibilityComparatees.map((comparatee) =>
                                            <TableCell
                                                className={isSelected(comparatee) ? 'highlighted' : ''}
                                            >
                                                {comparatee.group}
                                            </TableCell>)
                                    }
                                </TableRow>
                                <TableRow>
                                    <TableCell variant='head'>Brand</TableCell>
                                    <TableCell>{comparator.brand}</TableCell>
                                    {
                                        visibilityComparatees.map((comparatee) =>
                                            <TableCell
                                                className={isSelected(comparatee) ? 'highlighted' : ''}
                                            >
                                                {comparatee.brand}
                                            </TableCell>)
                                    }
                                </TableRow>
                                <TableRow className='row-style1'>
                                    <TableCell variant='head'>Model</TableCell>
                                    <TableCell>{comparator.model}</TableCell>
                                    {
                                        visibilityComparatees.map((comparatee) =>
                                            <TableCell
                                                className={isSelected(comparatee) ? 'highlighted' : ''}
                                            >
                                                {comparatee.model}
                                            </TableCell>)
                                    }
                                </TableRow>
                                <TableRow>
                                    <TableCell variant='head'>
                                        Similarity
                                        {
                                            renderSortButton(isSimilarityAsc, setIsSimilarityAsc)
                                        }
                                    </TableCell>
                                    <TableCell></TableCell>
                                    {
                                        visibilityComparatees.map((comparatee, index) =>
                                            <TableCell
                                                className={isSelected(comparatee) ? 'highlighted' : ''}
                                                onClick={() => {
                                                    handleDisplayedDetails(comparatee);
                                                }}
                                            >
                                                <Tooltip title='Show Details'>
                                                    <Typography component={Link}>
                                                        {(comparatee.similarity * 100).toFixed(2)}%
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>)
                                    }
                                </TableRow>
                            </Table>
                        </TableContainer>
                        {
                            renderDetailsTable({
                                tecaData: data?.components?.map(({ component_name, ta_component }) => ({ component_name, ta_component }))
                            })
                        }
                    </Paper>
                </Grid>
                {
                    (shouldDisplayHideTab || shouldDisplayRemoveTab) &&
                    <Grid item xs={2}>
                        <Paper sx={{
                            width: '100%',
                            height: '100%',
                            overflowX: 'auto',
                            overflowY: 'auto',
                        }}>
                            {
                                shouldDisplayRemoveTab &&
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    mr: 1,
                                }}>
                                    {
                                        renderVisibilityTab('Remove List', removeComparatees, setRemoveComparatees)
                                    }
                                    <Button onClick={() => setConfirmationOpen(true)}>
                                        Delete
                                    </Button>
                                </Box>
                            }
                            {
                                shouldDisplayHideTab && shouldDisplayRemoveTab &&
                                <hr class='solid' />
                            }
                            {
                                shouldDisplayHideTab &&
                                renderVisibilityTab('Hide List', invisibilitySet, setInvisibilitySet)
                            }

                        </Paper>
                    </Grid>

                }

            </Grid>
        </Box >
    );
}


export default ComparisonResultPage;
