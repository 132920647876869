import { Grid, TextField, } from '@mui/material';
import { useEffect, useState } from 'react';
import ConnectorSelection from '../ConnectorSelection';

const MissingInfoCard = (props) => {
    const { item, index, componentName, connectorId, onChange, data } = props
    const [selectedConnector, setSelectedConnector] = useState({})

    useEffect(() => {
        setSelectedConnector(getSelectedConnector(item?.connector_id || item?.editted_connector_id))
    }, [item])

    const getSelectedConnector = (defaultValue) => {
        return defaultValue !== null ?
            (data && data.find(connector => connector?.id === defaultValue)) : null
    }

    const onConnectorChange = (e) => {
        const connector = e.target.value || {}
        setSelectedConnector(connector)
        onChange(componentName, connectorId, index, {
            ...item,
            'connector_id': connector?.id,
            'connector_diagram_id': connector?.connector_diagram_id,
        })
    }

    return (
        <Grid container
            sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 1
            }}
        >
            {
                data &&
                <Grid item xs={3}>
                    <ConnectorSelection
                        data={data}
                        value={selectedConnector}
                        onChange={onConnectorChange}
                    />
                </Grid>
            }

            {data && <Grid xs={1} />}

            <Grid item xs={2}>
                <TextField
                    id='pin-label-id'
                    label='Pin'
                    variant='outlined'
                    defaultValue={item?.number}
                    disabled
                />
            </Grid>

            <Grid item xs={3} sx={{ ml: 0.5 }}>
                <TextField
                    id='pin-id-label-id'
                    label='Pin ID'
                    variant='outlined'
                    defaultValue={item?.name}
                    disabled
                />
            </Grid>
        </Grid>
    )
}

export default MissingInfoCard;