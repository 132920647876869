import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddUserPopup from './AddUserPopup';
import EditUserPopup from './EditUserPopup';
import RefreshIcon from '@mui/icons-material/Refresh';


const buttonSx = {
    borderRadius: 5,
    fontSize: 'inherit',
    alignItems: 'center',
    bgcolor: 'inherit',
    '&:hover': { bgcolor: '#e9eef5' },
};


const UserManagementBar = (props) => {
    const { callGraphAPI, fetchUserData } = props;
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const { selected } = props;
    const { loading } = props;

    const handleClose = () => {
        setOpenAddPopup(false);
        setOpenEditPopup(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            m: 1,
            width: 250,
            marginLeft: 'auto',
        }}
        >
            <IconButton
                size='large'
                sx={buttonSx}
                onClick={async () => await fetchUserData()}
            >
                <RefreshIcon color='primary' />
            </IconButton>
            <IconButton
                size='large'
                sx={buttonSx}
                disabled={loading}
                onClick={(e) => setOpenAddPopup(true)}
            >
                <AddIcon color={loading ? 'disabled' : 'primary'} />
            </IconButton>

            <IconButton
                size='large'
                sx={buttonSx}
                disabled={selected.length === 0}
                onClick={(e) => setOpenEditPopup(true)}
            >
                <EditIcon color={selected.length === 0 ? 'disabled' : 'primary'} />
            </IconButton>

            <AddUserPopup
                open={openAddPopup}
                onClose={handleClose}
                callGraphAPI={callGraphAPI}
            />
            <EditUserPopup
                open={openEditPopup}
                onClose={handleClose}
                callGraphAPI={callGraphAPI}
                selected={selected}
            />
        </Box>
    )
}

export default UserManagementBar;
