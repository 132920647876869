import React from 'react';
import WiringDiagramPage from '../WiringDiagramPage';

import '../../../styles/appStyle.css'


function HomePage() {
    return (
        <WiringDiagramPage />
    );
}

export default HomePage;
