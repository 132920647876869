import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { COMPARISON_STATUS_VALUES } from '../../constants';
import StatusButtonWithModal from '../../components/StatusButtonWithModal';

const DEFAULT_HIDDEN_ITEM_NUMBER = 5

function ComparateeTag(props) {
  const { value } = props
  const index = props?.row?.index
  const [isExpanded, setIsExpanded] = useState(false)
  const length = value ? value.length : 0
  let hiddenItem = length - DEFAULT_HIDDEN_ITEM_NUMBER
  const renderChip = (data) => {
    return (
      data.map((comparatee, index) => {
        return (
          <>
            {
              comparatee && <Chip
                data-testid={`comparatee-chip-${comparatee}-${index}`}
                color='primary'
                variant='outlined'
                key={comparatee}
                label={comparatee}
              />
            }
          </>
        )
      })
    )
  }

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      columnGap: 0.5,
      overflow: 'hidden',
      overflowX: 'auto',
    }}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {
        renderChip(isExpanded ? value || [] : value.slice(0, DEFAULT_HIDDEN_ITEM_NUMBER))
      }
      {
        !isExpanded && (
          hiddenItem > 0 &&
          <Typography
            data-testid={`hidden-item-label-${index}`}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            + {hiddenItem}
          </Typography>
        )
      }
    </Box>
  )
}


function StatusTag(props) {
  const { value, row } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleNavigation = () => {
    const parentRoute = location.pathname.split('/')?.at(1) || ''
    navigate(`/${parentRoute}/comparison-result`,
      {
        state: {
          dataFromDiagramPicker: props.row.id.toString(),
          author: row?.author
        }
      }
    );
  };

  const handleStatusClick = (event) => {
    event.stopPropagation()
    if (value === COMPARISON_STATUS_VALUES.SUCCESS) {
      handleNavigation();
    }
  }

  let bgColor = '#bbbbbb';
  let message = 'Comparison is in progress.';

  if (value === COMPARISON_STATUS_VALUES.SUCCESS) {
    bgColor = '#30CB83';
  } else if (value === COMPARISON_STATUS_VALUES.FAILED) {
    bgColor = '#D9534F';
    message = props.row.error;
  } else if (value === COMPARISON_STATUS_VALUES.PENDING) {
    bgColor = '#5BC0DE';
  }

  return (
    <StatusButtonWithModal
      bgColor={bgColor}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleStatusClick={handleStatusClick}
      value={value}
    />
  )
}


export default function ComparisonDataGrid(props) {
  const { rows, isLoading, showCheckboxSelection, onRowSelectionChange } = props;
  const { snackbar, handleCloseSnackbar, processRowUpdate, handleProcessRowUpdateError } = props;
  const { customColumns } = props;  // Used for testing

  function ExpandableCell({ value }) {
    const [expanded, setExpanded] = React.useState(false);
    return (
      <div
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
        {expanded ? value : (value && value.slice(0, 50))}&nbsp;
        {value && value.length > 50 && (
          <Link
            type="button"
            component="button"
            sx={{ fontSize: "inherit" }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </div>
    );
  }

  let columns;
  if (customColumns != null) {
    columns = customColumns;
  } else {
    columns = [
      {
        field: 'index',
        headerName: 'No.',
        minWith: 50,
        headerAlign: 'center',
        align: 'center'
      },
      {
        field: 'comparator',
        headerName: 'Comparator',
        flex: 0.1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'comparateeList',
        headerName: 'Comparatee List',
        flex: 0.45,
        headerAlign: 'center',
        align: 'left',
        renderCell: ComparateeTag
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 0.15,
        headerAlign: 'center',
        align: 'center',
        editable: true,
        renderCell: (params) => <ExpandableCell {...params} />,
      },
      {
        field: 'author',
        headerName: 'Author',
        flex: 0.1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <StatusTag shouldDisplayModifyButton={false} {...params} />,
      },
    ];
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        data-testid='compare-diagram-table'
        disableRowSelectionOnClick
        autoHeight
        loading={isLoading}
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        isCellEditable={() => showCheckboxSelection}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
        }}
        paginationMode='client'
        showColumnVerticalBorder
        showCellVerticalBorder
        pageSizeOptions={[10, 25]}
        checkboxSelection={showCheckboxSelection}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          onRowSelectionChange(selectedRows);
        }}
        disableColumnMenu
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={5000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
