import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default class DuplicatedDiagramDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: props.shouldOpenDialog,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.shouldOpenDialog !== this.props.shouldOpenDialog) {
            this.setState({ dialogOpen: nextProps.shouldOpenDialog });
        }
    }

    handleReplace = (e) => {
        this.props.handleReplace(e, [this.props.diagram], false)
        this.setState({ dialogOpen: false });
        this.props.removeAfterUpload(this.props.index);
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    key={`dialog-${this.props.diagram?.name}`}
                    open={this.state.dialogOpen}
                    onClose={() => {
                        this.setState({ dialogOpen: false });
                        }}>
                    <DialogTitle id='alert-dialog-title'>
                        {'Replace duplicated diagrams?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                        {this.props.diagram?.name} diagram has already been uploaded. Do you want to replace this diagram?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => {
                                this.setState({ dialogOpen: false })
                                this.props.removeAfterUpload(this.props.index)
                            }}>
                                Skip
                        </Button>
                        <Button 
                            onClick={this.handleReplace}>
                                Replace
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
       
    }
}
