import { Box, TextField, ThemeProvider, Typography, createFilterOptions, createTheme } from '@mui/material'
import StyledAutocomplete from '../../components/CustomAutocomplete';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { isObjectEmptyOrNull } from '../../common/utils';

const DiagramPicker = (props) => {
    const outerTheme = useTheme();

    const { filteredDiagrams, filteredComparateeDiagrams } = props
    const { shouldShowSimilarity } = props
    const { id, index, onChange } = props
    const originDiagram = props?.originDiagram || null;
    const targetDiagrams = props?.targetDiagrams || [];
    const [inputTarget, setInputTarget] = useState('');
    const [ctorPickerLoading, setCtorPickerLoading] = useState(false);
    const [cteePickerLoading, setCteePickerLoading] = useState(false);

    return (
        <ThemeProvider theme={customTheme(outerTheme)}>
            <Box
                {...props}
                id={`diagram-picker-${id}`}
            >
                <Box width={'50%'}>
                    <StyledAutocomplete
                        data-testid='comparator-selection-id'
                        id={`comparator-selection-${id}`}
                        loading={ctorPickerLoading}
                        value={originDiagram}

                        onChange={(event, newValue) => {
                            onChange(index, {
                                comparator: newValue
                            })
                        }}
                        options={filteredDiagrams || []}
                        sx={{ maxWidth: 600, height: 55 }}
                        renderInput={(params) =>
                            <TextField {...params}
                                error={isObjectEmptyOrNull(originDiagram)}
                                label='Comparator'
                                required
                            />
                        }
                    />
                </Box>

                <Box width={'50%'}>
                    <StyledAutocomplete
                        shouldShowSimilarity={shouldShowSimilarity}
                        isComparteeSelection={true}
                        loading={cteePickerLoading}
                        disableCloseOnSelect
                        limitTags={1}
                        data-testid='comparatees-selection-id'
                        id={`comparatees-selection-${id}`}
                        multiple
                        value={targetDiagrams}
                        onChange={(event, newValue) => {
                            onChange(index, {
                                comparateeList: newValue
                            })
                        }}
                        inputValue={inputTarget}
                        onInputChange={(event, newValue, reason) => {
                            if (reason !== 'reset') {
                                setInputTarget(newValue)
                            }
                        }}
                        options={filteredComparateeDiagrams || []}
                        sx={{
                            maxWidth: 600, height: 55
                        }}
                        ChipProps={{
                            color: 'primary', variant: 'outlined', sx: [{
                                display: 'flex',
                                overflow: 'hidden',
                                overflowX: 'auto',
                                columnGap: 0.5
                            }]
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                error={targetDiagrams.length === 0}
                                label='Comparatee(s)'
                                placeholder='Comparatee(s)'
                                required
                            />
                        }
                    />
                </Box>

            </Box>
        </ThemeProvider>

    )
}

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme?.palette?.mode,
        },
        components: {
            MuiAutocomplete: {
                defaultProps: {
                    renderOption: (props, option, state, ownerState) => {
                        const hasValue = option?.similarity || option?.similarity === 0
                        const similarity = hasValue ?
                            `${(option?.similarity * 100).toFixed(2)}%` : ''
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                data-testid='select-diagram-id'
                                component='li'
                                {...props}
                                key={`${option?.diagram_id}$${option?.diagram_name}`}
                            >

                                {
                                    ownerState?.shouldShowSimilarity &&
                                    ownerState?.isComparteeSelection &&
                                    <Typography
                                        component='span'
                                        sx={{
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {`${similarity}`}
                                    </Typography>

                                }
                                <Typography component='span'>
                                    {`${option?.diagram_name}`}
                                </Typography>

                            </Box>
                        )
                    },
                    filterOptions: filterOptions,
                    getOptionLabel: (option) => option?.diagram_name,
                },
            },
        },
    });

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    // uncomment if users want to limit the number of options
    // limit: 100, 
    stringify: (option) => {
        return option?.diagram_name
    },

});
export default DiagramPicker