import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


export default function DiagramPickerDialog(props) {
    const { isOpen, onClose, parentRoute } = props;
    const navigate = useNavigate();

    if (!isOpen) return null;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {'Success'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Diagrams are queued to compare. Would you like to leave this page?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Stay
                </Button>
                <Button onClick={() => {
                    onClose();
                    navigate(`/${parentRoute}`);
                }}>
                    Leave
                </Button>
            </DialogActions>
        </Dialog>
    )
};
