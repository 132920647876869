import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import ComponentDetails from '../ComponentDetails';
import plainTextTableGenerator from '../../common/tableGenerator';

export default function ComparisonDetailTreeView(props) {
  const { comparator, comparatee } = props
  const { isAscending } = props
  const { tecaData } = props
  const [data, setData] = useState([])

  const sortComponents = (list) => {
    return list.sort((a, b) => {
      return (isAscending ? 1 : -1) * (a < b ? -1 : 1);
    });
  }

  useEffect(() => {
    let newComponents = new Set()
    if (comparator) {
      newComponents = new Set(Object.keys(comparator))
    }
    if (comparatee) {
      Object.keys(comparatee).forEach(newComponents.add, newComponents)
    }
    setData(Array.from(newComponents))
  }, [comparator, comparatee])

  return (  
    <Box sx={{ minHeight: 180, width: '80%' }}>
      {
        comparator &&
        sortComponents(data)
          .map((componentName) => {
            const shouldDisplayRightSide = !!comparatee
            const leftSide = componentName in comparator ?
              plainTextTableGenerator({ data: comparator[componentName].sort() }) : ''
            const rightSide = comparatee && componentName in comparatee ?
              plainTextTableGenerator({ data: comparatee[componentName].sort() }) : ''
            const leftName = leftSide !== '' ? componentName : '___'
            const rightName = shouldDisplayRightSide ?
              (rightSide !== '' ? componentName : '___') : ''
            const defaultTecaId = 'Not Found';
            const tecaComponent = tecaData?.find(c => c.component_name === componentName);
            const tecaId = tecaComponent ? tecaComponent.ta_component : [defaultTecaId];

            return (
              <ComponentDetails
                leftName={leftName}
                rightName={rightName}
                leftSide={leftSide}
                rightSide={rightSide}
                tecaId={tecaId}
                shouldDisplayRightSide={shouldDisplayRightSide}
              />
            )
          })
      }
    </Box>
  );
}
