import { createTheme } from '@mui/material';
export const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    color: 'white',
                    backgroundColor: '#2286F7',
                    '&:hover': {
                        backgroundColor: '#4568dc',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2286F7',
                    color: 'white',
                    borderRadius: 2,
                    '&:hover': {
                        backgroundColor: '#4568dc',
                    },
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    color: 'black',
                    backgroundColor: 'white',
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    'margin-top': '1em',
                    'margin-bottom': '1em'
                },
                selectLabel: {
                    'margin-top': '1em',
                    'margin-bottom': '1em'
                }
            }
        },
        MuiAlert: {
            defaultProps: {
                componentsProps: {
                    closeButton: {
                        style: {
                            backgroundColor: 'inherit',
                            color: 'inherit',
                            opacity: 0.7
                        }
                    }
                }

            }
        }

    },
    typography: {
        fontFamily: 'Segoe UI Emoji',
    }
});