const COLUMN_LENGTH = [7, 4, 10, 10, 6, 6];
const HEADERS = ['Connector', 'Pin', 'Line', 'Harness', 'Solder', 'Plug'];


export default function plainTextTableGenerator(props) {
    const { data } = props
    if (data === []) return '';

    const headers = HEADERS
    let tableObject = {
        data: data || [],
        config: {
            rightAlign: headers,
            columnOffset: 2
        }
    };
    if (tableObject.data.length === 0) {
        return;
    }
    let i = 0;
    let k = 0;

    let tableData = {
        LineLength: 0,
        columns: [],
        columnLength: [],
        text: ''
    };

    //Store the column headers and init with cell size
    for (i = 0; i < headers.length; i++) {
        tableData.columns.push([headers[i]])
        tableData.columnLength.push(COLUMN_LENGTH[i])
    }

    //Build column data and find the largest cell in each column
    tableObject.data.forEach(function (row) {
        let j = 0;
        for (let cell of row) {
            tableData.columns[j].push(cell);
            cell.length > tableData.columnLength[j] &&
                (tableData.columnLength[j] = cell.length);
            j++;
        }
    });

    tableData.LineLength = COLUMN_LENGTH

    //Generate top border
    tableData.text +=
        tableData.columnLength.reduce(function (a, c) {
            let ret = '';
            for (i = 0; i < c + tableObject.config.columnOffset; i++) {
                ret += '-';
            }
            ret += '+';
            return a + ret;
        }, '+') + '\n';

    //Generate Headers
    tableData.text +=
        tableData.columns.reduce(function (a, c, j) {
            let ret = c[0];
            for (
                i = c[0].length;
                i < tableData.columnLength[j] + tableObject.config.columnOffset;
                i++
            ) {
                ret += ' ';
            }
            ret += '|';
            return a + ret;
        }, '|') + '\n';

    //Generate header border
    tableData.text +=
        tableData.columnLength.reduce(function (a, c) {
            let ret = '';
            for (i = 0; i < c + tableObject.config.columnOffset; i++) {
                ret += '=';
            }
            ret += '+';
            return a + ret;
        }, '+') + '\n';

    //Generate rest of the rows
    //-- Determine iteration length
    let iL = tableData.columns[0].length;
    for (k = 1; k < iL; k++) {
        let line =
            tableData.columns.reduce(function (a, c, j) {
                let ret = '';
                if (tableObject.config.rightAlign.indexOf(c[0]) < 0) {
                    ret = c[k];
                }
                if (tableObject.config.rightAlign.indexOf(c[0]) > -1) {
                    ret += c[k];
                }
                // Generate space for aligning
                for (
                    i = c[k].length;
                    i < tableData.columnLength[j] + tableObject.config.columnOffset;
                    i++
                ) {
                    ret += ' ';
                }
                ret += '|';
                return a + ret;
            }, '|') + '\n';
        tableData.text += line
    }

    return tableData?.text || ''
}
