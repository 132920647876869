import { useContext, useEffect, useState } from 'react'
import { MissingInfoContext, UserContext } from '../App/appContext'
import { Button, CircularProgress } from '@mui/material'
import { fetchMissingInfo } from './fetchMissingInfo'
import { useLocation } from 'react-router-dom'
import { isStringEmptyOrBlank } from '../../common/utils'
import { getSignedUrlS3Object } from '../../common/getS3Object'
import envConfig from '../../../environments';

const MissingInfoAlert = (props) => {
    const { missingInfo, setMissingInfo } = useContext(MissingInfoContext)
    const { currUser, setCurrUser } = useContext(UserContext)
    const { shouldDisplayUpdateInformation } = props
    const { diagramId, imageUri } = props
    const { setAlert } = props
    const [loadingComponents, setLoadingComponents] = useState(false)
    const [loadingPins, setLoadingPins] = useState(false)
    const location = useLocation()

    useEffect(() => {
        fetchMissingInfo(diagramId, setMissingInfo, updateLoading, currUser, setCurrUser)
    }, [currUser])

    const updateLoading = (state) => {
        setLoadingComponents(state)
        setLoadingPins(state)
    }

    const openDiagramDetailPage = async () => {
        let currentPath = `${location.pathname}`
        if (isStringEmptyOrBlank(currentPath) || currentPath === '/') {
            currentPath = '/wiring-diagram'
        }
        const url = `${currentPath}/diagram/${diagramId}`
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const openImageInNewTab = async () => {
        if (isStringEmptyOrBlank(imageUri)) {
            console.log('Cannot open image because the image URI is missing')
            if (setAlert) {
                setAlert({
                    severity: 'error',
                    title: 'Error',
                    message: 'Cannot open image because the image URI is missing'
                })
            }
            return
        }

        let bucketName = envConfig.WIRING_DIAGRAM_BUCKET;
        if ( imageUri.includes(envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET) ) {
            bucketName = envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET;
        }

        try {
            const pathParts = imageUri.split('/').slice(3);
            const imagePath = pathParts.join('/');
            const url = await getSignedUrlS3Object(imagePath, 4500, bucketName);
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (error) {
            console.error('Failed to retrieve the signed URL:', error);
        }
    };

    return (
        <div>
            <span style={{ display: 'flex', gap: 5 }}>
                <p data-testid='missingPinsLabelId'>Missing Pins: </p>
                <span>
                    {
                        loadingPins &&
                        <CircularProgress
                            data-testid='loading-indicator-id'
                            size='1.5rem' />
                    }
                </span>
                {!loadingComponents &&
                    <p data-testid='missingTotalPinsId'>
                        {missingInfo?.totalPins}
                    </p>
                }
            </span>
            {shouldDisplayUpdateInformation &&
                <>
                    <span style={{ display: 'flex', gap: 5 }}>
                        <p>Updated Pins: </p>
                        <span>
                            {
                                loadingPins &&
                                <CircularProgress
                                    data-testid='loading-indicator-id'
                                    size='1.5rem' />
                            }
                        </span>
                        {!loadingComponents &&
                            <p>
                                {missingInfo?.updatedPins}
                            </p>
                        }
                    </span>
                    <span style={{ display: 'flex', gap: 5 }}>
                        <p>Not Updated Pins: </p>
                        <span>
                            {
                                loadingPins &&
                                <CircularProgress
                                    data-testid='loading-indicator-id'
                                    size='1.5rem' />
                            }
                        </span>
                        {!loadingComponents &&
                            <p>
                                {missingInfo?.totalPins - missingInfo?.updatedPins}
                            </p>
                        }
                    </span>
                    <hr />
                </>
            }

            <span style={{ display: 'flex', gap: 5 }}>
                <p data-testid='missingComponentLabelId'>Missing Components: </p>
                <span>
                    {
                        loadingComponents &&
                        <CircularProgress
                            data-testid='loading-indicator-id'
                            size='1.5rem' />
                    }
                </span>
                {!loadingComponents &&
                    <p data-testid='missingTotalComponentId'>
                        {missingInfo?.components?.length}
                    </p>}
            </span>
            {shouldDisplayUpdateInformation &&
                <>
                    <span style={{ display: 'flex', gap: 5 }}>
                        <p>Updated Components: </p>
                        <span>
                            {
                                loadingPins &&
                                <CircularProgress
                                    data-testid='loading-indicator-id'
                                    size='1.5rem' />
                            }
                        </span>
                        {!loadingComponents &&
                            <p>
                                {missingInfo?.updatedComponents}
                            </p>
                        }
                    </span>
                    <span style={{ display: 'flex', gap: 5 }}>
                        <p>Not Updated Components: </p>
                        <span>
                            {
                                loadingPins &&
                                <CircularProgress
                                    data-testid='loading-indicator-id'
                                    size='1.5rem' />
                            }
                        </span>
                        {!loadingComponents &&
                            <p>
                                {missingInfo?.components?.length - missingInfo?.updatedComponents}
                            </p>
                        }
                    </span>
                </>
            }
            <div
                style={{
                    position: 'fixed',
                    bottom: 10,
                    right: 10,
                    gap: 5,
                    display: 'flex',
                }}
            >
                <Button
                    onClick={async () => {
                        await openImageInNewTab()
                    }}
                >
                    Open Diagram
                </Button>
                <Button
                    onClick={() => openDiagramDetailPage()}
                >
                    Edit
                </Button>
            </div>
        </div>
    )
}

export default MissingInfoAlert;
