import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const ConnectorSelection = (props) => {
    const { data, onChange } = props
    const { value } = props

    return (
        <FormControl fullWidth>
            <InputLabel>Connector</InputLabel>
            <Select
                label='Connector'
                value={value}
                onChange={onChange}
            >
                {
                    data && data.map(item =>
                        <MenuItem key={item?.id} value={item}>
                            {item?.name} - {item?.connector_diagram_id}
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default ConnectorSelection;