import { useEffect, useState } from 'react';


const useMessageWithTimeout = (initialMessage, timeout = 5000) => {
    const [message, setMessage] = useState(initialMessage);
    useEffect(() => {
        let timeoutId;
        if (message !== '') {
            timeoutId = setTimeout(() => {
                setMessage('');
            }, timeout);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [message, timeout]);
    return [message, setMessage];
};

export default useMessageWithTimeout;
