import MissingInfoTreeView from '.'
import ConnectorSelection from '../ConnectorSelection'
import MissingInfoCard from '../MissingPinCard'

const MissingPins = (props) => {
    const { connectorId, componentName } = props
    const { selectedConnector, setSelectedConnector } = props
    const { onConnectorChange, onMainConnectorChange } = props
    const { connectorList, pinList } = props

    const onConnectorSelectionChange = (e) => {
        const connector = e.target.value || {}
        setSelectedConnector(connectorId, connector)
        onMainConnectorChange(componentName, connectorId, connector)
    }

    return (
        <MissingInfoTreeView
            label={connectorId}
            connectorElement={
                <ConnectorSelection
                    data={connectorList}
                    value={selectedConnector}
                    onChange={onConnectorSelectionChange}
                />
            }
            element={
                pinList.map((pin, idx) => {
                    return (
                        <MissingInfoCard
                            index={idx}
                            componentName={componentName}
                            connectorId={connectorId}
                            item={pin}
                            data={connectorList}
                            onChange={onConnectorChange}
                        />
                    )
                })
            }
        />
    )
}

export default MissingPins