const getEnvConfig = env => {
  switch (env) {
    case 'local':
      return require(`./env.local`).default;
    case 'staging':
      return require(`./env.staging`).default;
    case 'prod':
      return require(`./env.prod`).default;
    default:
      return {};
  }
};

const env = process.env.REACT_APP_DEPLOYMENT_ENV;

const envConfig = {
  ...getEnvConfig(env),
  ENV: env,
};

export default envConfig;
