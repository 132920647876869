import { Fragment } from 'react';
import { CardMedia } from '@mui/material';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const card = (img) => {
  return (
  <Fragment>
      <CardMedia
        component='img'
        height='200'
        width='120'
        image={img?.preview}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      />
      <CardContent>
          <Typography gutterBottom variant='body1' fontSize={12} component='p'>
            {img?.name}
        </Typography>
      </CardContent>
    </Fragment>
  )
}

export default function DiagramCard({img}) {
  return (
    <Box sx={{ height: 220, width: 150 }}>
      <Card variant='outlined' sx={{ borderRadius: 2 }}>{card(img)}</Card>
    </Box>
  );
}
