import envConfig from '../../../environments';
import { DIAGRAM_COMPARISON_PATH, DIAGRAM_LIST_PATH } from '../../constants';
import { getAccessToken } from '../LoginPage/auth';

export const getDiagramForComparison = async (comparatorId, currUser, setCurrUser, params = null) => {
    if (params === null) {
        params = new URLSearchParams()
    }
    if (comparatorId !== null) {
        params.append('comparator_id', comparatorId)
    }

    const accessToken = await getAccessToken(currUser, setCurrUser);
    const data = await fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_LIST_PATH}?${params.toString()}`, {
        method: 'GET',
        headers: { 'auth': accessToken },
    })
        .then(res => res.json())
        .then(data_res =>
            data_res?.diagrams || []
        )
        .catch((error) => {
            console.log(`Server error: ${error}`);
        });
    return data
}

export const createComparison = (
    id,
    comparatorId,
    comparateeIdList,
    currUser,
    setCurrUser
) => {
    return new Promise((resolve, reject) => {
        getAccessToken(currUser, setCurrUser).then(
            (token) => {
                fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_COMPARISON_PATH}`, {
                    method: 'POST',
                    headers: {
                        'auth': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        comparator_id: comparatorId,
                        comparatee_id_list: comparateeIdList,
                        author: currUser.username,
                        note: '',
                    })
                }).then((resp) => {
                    if (resp.status !== 204) {
                        console.log('Server error');
                        reject({ message: `Server error ${resp.status}` })
                    } else {
                        resolve(id)
                    }
                }).catch((err) => {
                    console.log(`Server error: ${err}`)
                    reject({ message: `Server error: ${err}` })
                });
            });
    })
}