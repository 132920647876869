import { useContext } from 'react';
import { IconButton } from '@mui/material';
import { UserContext } from '../../containers/App/appContext';
import { signOut } from '../../containers/LoginPage/auth';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ManageAccountsOutlined, LogoutOutlined } from '@mui/icons-material';

import '../../../styles/appStyle.css'
import { useNavigate } from 'react-router-dom';


export default function CustomAppBar({ callback }) {
    const { currUser, setCurrUser } = useContext(UserContext)
    const navigate = useNavigate();

    const onSignOut = (() => {
        signOut()
        setCurrUser({})
    })

    const navigateToUserProfile = (() => {
        navigate(`/user-profile`);
    })
    return (
        <Box sx={{ flexGrow: 1, width: '100vw' }}>
            <AppBar position='static'>
                <Toolbar className='primary-color' sx={{ gap: 1 }}>
                    <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                        Wiring Diagram Tool
                    </Typography>
                    <Typography>
                        Hello {currUser?.displayName || currUser?.username}
                    </Typography>
                    <IconButton
                        sx={{
                            bgcolor: 'inherit',
                            borderRadius: 3
                        }}
                        onClick={navigateToUserProfile}
                    >
                        <ManageAccountsOutlined sx={{ fontSize: 35 }} />
                    </IconButton>
                    <IconButton
                        sx={{
                            mr: 2,
                            bgcolor: 'inherit',
                            borderRadius: 3
                        }}
                        onClick={onSignOut}
                    >
                        <LogoutOutlined sx={{ fontSize: 35 }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
