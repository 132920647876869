import {Alert, AlertTitle, Box, Paper, Typography} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import UserDataGrid from '../../components/DataGrid/userDataGrid';
import { fetchGraphData, getUsersWithGroupsInUserPool, isAdminUser, requestAccessToken } from './user';
import UserManagementBar from './UserManagementBar';
import { GLOBAL_WIDTH_OFFSET, GROUP_MEMBERS_API } from '../../constants';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { UserContext } from '../App/appContext';
import { useNavigate } from 'react-router-dom';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';


const UserManagementPage = () => {
    const { currUser } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [users, setUsers] = useState([]);
    const [isUsersLoading, setIsUserLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [loginSuccessMsg, setLoginSuccessMsg] = useState('');
    const [loginErrorMsg, setLoginErrorMsg] = useState('');
    const isAdmin = isAdminUser(currUser);
    const navigate = useNavigate();

    const scrollbarWidth = getScrollbarWidth();
    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    useResizeHandler(setContainerDimensions);

    useEffect(() => {
        const asyncResult = async () => {
            setIsUserLoading(true)
            await loginAzure().then(async (response) => {
                if (response && response.accessToken) {
                    instance.setActiveAccount(response)
                    await fetchUserData()

                }
            })
        }
        if (!isAdmin) {
            navigate('/wiring-diagram')
        } else {
            asyncResult()
        }
    }, [])

    useEffect(() => {
        if (loginErrorMsg !== '') {
            resetMsg(setLoginErrorMsg, '')
        }
        if (loginSuccessMsg !== '') {
            resetMsg(setLoginSuccessMsg, '')
        }
    }, [loginSuccessMsg, loginErrorMsg])

    const resetMsg = (setState, msg) => {
        setTimeout(() => {
            setState(msg)
        }, 5000)
    };

    const fetchUserData = async () => {
        setIsUserLoading(true)
        await getUsersWithGroupsInUserPool().
            then(async result => {
                await fetchUserInGroup(result || [])
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsUserLoading(false)
            })
    };

    const loginAzure = async () => {
        const loginRequest = {
            scope: [
                'GroupMember.ReadWrite.All',
                'Group.ReadWrite.All',
                'User.ReadBasic.All',
                'User.ReadWrite',
                'profile',
                'email'
            ],
        }
        try {
            const response = await instance.ssoSilent(loginRequest);
            setLoginAlert(response)
            return response
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                const loginResponse = await instance.loginPopup(loginRequest)
                    .then(response => {
                        setLoginAlert(response)
                        return response
                    })
                    .catch(err => {
                        console.log('Login Popup get error ' + err)
                        setLoginErrorMsg('Failed login Azure')

                    })
                return loginResponse
            } else {
                console.log(err)
            }
        }
    }

    const setLoginAlert = (response) => {
        if (response && response?.accessToken) {
            setLoginSuccessMsg('Successfully login Azure')
        } else {
            setLoginErrorMsg('Failed login Azure')
        }
    }

    const callGraphAPI = async (endpoint, method = 'GET', body = null) => {
        const token = await requestAccessToken(instance, accounts[0])
        const data = await fetchGraphData(endpoint, token, method, body)
        return data
    }

    const fetchUserInGroup = async (usersInCognito) => {
        await callGraphAPI(`${GROUP_MEMBERS_API}`).then(
            response => {
                if (response && response?.value) {
                    const userArr = response?.value.map(user => {
                        return {
                            'id': user?.mail,
                            'displayName': user?.displayName,
                            'email': user?.mail,
                            'memberId': user?.id || null
                        }
                    })
                    const addedUser = new Set()
                    let mergedInfo = usersInCognito.map(userA => {
                        const foundUser = userArr.find(userB => userB?.email === userA.email) || {}
                        if (foundUser) {
                            addedUser.add(foundUser?.id)
                        }
                        return {
                            ...foundUser,
                            ...userA,
                        }
                    })
                    const missingUsers = userArr.filter(user => !addedUser.has(user.id)).map(user => ({
                        ...user,
                        disabled: true
                    }))
                    const result = [...mergedInfo, ...missingUsers].sort((a, b) => a.id.length > b.id.length ? 1 : -1)
                    setUsers(result)
                }
            }
        )
    };

    const renderUserManagementPage = () => {
        return (

                <Box
                    sx={{ bgcolor: 'lightgray'  }}
                    height={containerDimensions.height}
                    width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
                >
                    {
                        loginErrorMsg !== '' &&
                        <Alert severity='error'>
                            <AlertTitle>Error</AlertTitle>
                            {loginErrorMsg}
                        </Alert>
                    }
                    {
                        loginSuccessMsg !== '' &&
                        <Alert severity='success'>
                            <AlertTitle>Success</AlertTitle>
                            {loginSuccessMsg}
                        </Alert>
                    }
                    <Box
                        className='container'
                        maxWidth
                        sx={{ my: 0.5, px: 0.5, pb: 0.5, pt: 0.5 }}
                        justifyContent={'space-between'}
                        display={'flex'}
                    >
                        <Paper sx={{
                            height: 75,
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            px: 1
                        }}>
                            <Typography variant='h4' component='h5' sx={{ alignItems: 'center' }}>
                                User Management
                            </Typography>

                        </Paper>
                    </Box>
                    <Box sx={{ my: 0.5, px: 0.5, pb: 0.5 }}>
                        <Paper
                            sx={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 1,
                            }}
                        >
                            <UserManagementBar
                                loading={isUsersLoading}
                                callGraphAPI={callGraphAPI}
                                selected={selected}
                                fetchUserData={fetchUserData}
                            />
                            <UserDataGrid
                                users={users}
                                loading={isUsersLoading}
                                setSelected={setSelected}
                                callGraphAPI={callGraphAPI}
                            />
                        </Paper>
                    </Box>
                </Box>
        )
    };

    return (
        <Box sx={{ bgcolor: 'lightgray' }} height='100%' width='100%'>
            {renderUserManagementPage()}
        </Box >
    );
}

export default UserManagementPage;
