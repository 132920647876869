import { Icon, Input } from 'semantic-ui-react';


const SearchBar = ({ searchQuery, setSearchQuery, defaultPaginationModel, setPaginationModel, fetchData }) => {
  const handleSearch = () => {
    if (defaultPaginationModel) {
      setPaginationModel(defaultPaginationModel);
    }
    fetchData();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
      <div>
        <Input
          icon={
            <Icon
              name='search'
              link
              onClick={handleSearch}
              data-testid='search-icon'
            />
          }
          placeholder='Search...'
          style={{ borderRadius: '30px' }}
          onChange={(event) => setSearchQuery(event.target.value)}
          onKeyPress={handleKeyPress}
          value={searchQuery}
        />
      </div>
    );
};

export default SearchBar;
