import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Box, IconButton, Paper, Typography } from '@mui/material';
import { getSignedUrlS3Object } from '../../common/getS3Object';
import { DIAGRAM_UPLOAD_HISTORY_PATH, GLOBAL_WIDTH_OFFSET, URL_DELIMINATOR } from '../../constants';
import { MissingInfoContext, UserContext } from '../App/appContext';


import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DiagramDataGrid from '../../components/DataGrid/diagramDataGrid';
import DiagramFilter from '../../components/DiagramFilter';
import SearchBar from '../../components/SearchBar';
import UploadPage from '../UploadPage';
import envConfig from '../../../environments';
import getScrollbarWidth from '../../common/scrollbarWidth';
import useResizeHandler from '../../common/windowSizeHandler';

import '../../../styles/appStyle.css';
import DiagramMisingInfoPage from '../DiagramMissingInfoPage';
import { isObjectEmptyOrNull } from '../../common/utils'
import { getAccessToken } from '../LoginPage/auth';

function WiringDiagramPage() {
    const { currUser, setCurrUser } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rowCountState, setRowCountState] = useState(0);
    const [missingInfo, setMissingInfo] = useState({});
    const [alert, setAlert] = useState({})

    const [containerDimensions, setContainerDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const navigate = useNavigate();
    const location = useLocation();

    const defaultFilters = {
        groups: [],
        brands: [],
        models: [],
        domainValues: [],
        functionValues: [],
        engineCodes: [],
        status: [],
    };
    const [conditions, setConditions] = useState(defaultFilters);
    const defaultPaginationModel = {
        page: 0,
        pageSize: 10,
    };
    const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
    const scrollbarWidth = getScrollbarWidth();

    useResizeHandler(setContainerDimensions);

    useEffect(() => {
        fetchData()
    }, [currUser, paginationModel.page, setPaginationModel]);


    const fetchData = async () => {
        if (currUser === null) return;
        const { groups, models, brands, domainValues, functionValues, engineCodes, status } = conditions;
        const params = new URLSearchParams({
            'skip': paginationModel.page,
            'limit': 10,
            'groups': groups?.join(URL_DELIMINATOR),
            'models': models?.join(URL_DELIMINATOR),
            'brands': brands?.join(URL_DELIMINATOR),
            'status': status?.join(URL_DELIMINATOR),
        });
        if (searchQuery && searchQuery !== '') {
            params.append('q', searchQuery);
        }
        // Support filter by empty string by having empty value as -1
        if (domainValues && domainValues.length !== 0) {
            const dValues = domainValues.map(item => (item === '' ? '-1' : item));
            params.append('domain_values', dValues.join(URL_DELIMINATOR))
        }
        if (functionValues && functionValues.length !== 0) {
            const fValues = functionValues.map(item => (item === '' ? '-1' : item));
            params.append('func_values', fValues.join(URL_DELIMINATOR))
        }
        if (engineCodes && engineCodes.length !== 0) {
            const eValues = engineCodes.map(item => (item === '' ? '-1' : item));
            params.append('engine_codes', eValues.join(URL_DELIMINATOR))
        }
        setIsLoading(true);
        const accessToken = await getAccessToken(currUser, setCurrUser)
        fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_UPLOAD_HISTORY_PATH}?${params.toString()}`, {
            method: 'GET',
            headers: { 'auth': accessToken },
        })
            .then(res => res.json())
            .then(data_res => {
                const newRows = data_res.diagrams.map(upload => createData(
                    upload.diagram_id,
                    upload.diagram_name,
                    upload.diagram_type,
                    upload.group,
                    upload.model,
                    upload.brand,
                    upload.domain_value,
                    upload.function_value,
                    upload.engine_code,
                    upload.status,
                    upload.error,
                    upload.image_uri,
                ));
                setIsLoading(false);
                setRowCountState(data_res.total);
                setRows(newRows);
            })
            .catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }
    const onFilterClick = () => {
        if (JSON.stringify(defaultPaginationModel) === JSON.stringify(paginationModel)) {
            fetchData()
        } else {
            setPaginationModel(defaultPaginationModel)
        }
    }

    function createData(
        id,
        diagramName,
        diagramType,
        group,
        model,
        brand,
        domainValue,
        functionValue,
        engineCode,
        status,
        error,
        imageUri,
    ) {
        return {
            id: id,
            diagramName: diagramName,
            diagramType: diagramType,
            group: group,
            model: model,
            brand: brand,
            domainValue: domainValue,
            functionValue: functionValue,
            engineCode: engineCode,
            status: status,
            error: error,
            imageUri: imageUri,
        };
    }

    const addWiringDiagrams = () => {
        let currentPath = `${location.pathname}`
        if (currentPath === null || currentPath === '' || currentPath === '/') {
            currentPath = '/wiring-diagram'
        }
        navigate(`${currentPath}/upload`)
    }

    const handleRowClick = async function (
        params,
        event,
        details
    ) {
        if (params.row.status === 'SUCCESS') {
            try {

                let bucketName = envConfig.WIRING_DIAGRAM_BUCKET;
                if ( params.row.imageUri.includes(envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET) ) {
                    bucketName = envConfig.WIRING_DIAGRAM_BULK_UPLOAD_BUCKET;
                }
                const pathParts = params.row.imageUri.split('/').slice(3);
                const imagePath = pathParts.join('/');
                const url = await getSignedUrlS3Object(imagePath, 4500, bucketName)
                window.open(url, '_blank', 'noopener,noreferrer');
            } catch (error) {
                console.error('Failed to retrieve the signed URL:', error);
            }
        } else {
            let currentPath = `${location.pathname}`
            if (currentPath === null || currentPath === '' || currentPath === '/') {
                currentPath = '/wiring-diagram'
            }
            navigate(
                `${currentPath}/upload`,
                {
                    state: {
                        filename: params.row.diagramName,
                        diagramType: params.row.diagramType,
                        brand: params.row.brand,
                        model: params.row.model,
                        group: params.row.group,
                        domainValue: params.row.domainValue,
                        functionValue: params.row.functionValue,
                        engineCode: params.row.engineCode,
                        multiple: false,
                    }
                }
            )
        }
    };

    const renderResultPage = () => {
        return (
            <Box
                sx={{ bgcolor: 'lightgray' }}
                // height={containerDimensions.height}
                width={containerDimensions.width - scrollbarWidth - GLOBAL_WIDTH_OFFSET}
            >
                <Box
                    className='container'
                    maxWidth
                    sx={{ my: 0.5, px: 0.5, pb: 0.5, pt: 0.5 }}
                    justifyContent={'space-between'}
                    display={'flex'}
                >
                    <Paper sx={{
                        height: 75,
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,  // align with Comparison result page
                        px: 1,
                    }}>
                        <SearchBar
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            defaultPaginationModel={defaultPaginationModel}
                            setPaginationModel={setPaginationModel}
                            fetchData={fetchData}
                        />
                        <Box sx={{ ml: 1 }}>
                            <IconButton
                                size='medium'
                                onClick={addWiringDiagrams}
                                sx={{
                                    borderRadius: 3,
                                    fontSize: 'inherit',
                                    justifyContent: 'space-between',
                                    width: 100
                                }}
                            >
                                <BackupOutlinedIcon sx={{ mr: 0.5 }}>
                                </BackupOutlinedIcon>
                                Upload
                            </IconButton>
                        </Box>
                        <Typography></Typography>
                    </Paper>
                </Box>
                <Box
                    sx={{ my: 0.5, px: 0.5, pb: 0.5 }} >
                    <Paper
                        sx={{
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 1
                        }}
                    >
                        <Box width='100%'>
                            {
                                !isObjectEmptyOrNull(alert) &&
                                <Alert
                                    onClose={() => setAlert({})}
                                    severity={alert?.severity}
                                >
                                    <AlertTitle>{alert?.title}</AlertTitle>
                                    {alert?.message}
                                </Alert>
                            }
                        </Box>
                        <DiagramFilter
                            conditions={conditions}
                            setConditions={setConditions}
                            successOnly={false}
                            defaultConditions={defaultFilters}
                            onFilterClick={onFilterClick}
                            currUser={currUser}
                            setCurrUser={setCurrUser}
                            shouldDisplayStatusFilter={true}
                        />
                        <DiagramDataGrid
                            rows={rows}
                            rowCountState={rowCountState}
                            isLoading={isLoading}
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                            handleRowClick={handleRowClick}
                            setAlert={setAlert}
                        />
                    </Paper>
                </Box>
            </Box >
        );
    }

    return (
        <MissingInfoContext.Provider value={{ missingInfo, setMissingInfo }}>
            <Box sx={{ bgcolor: 'lightgray' }} height='100%' width='100%'>
                <Routes>
                    <Route path='' element={renderResultPage()} />
                    <Route path='upload' element={<UploadPage />} />
                    <Route path='diagram/:diagramId' element={<DiagramMisingInfoPage />} />
                </Routes>
            </Box >
        </MissingInfoContext.Provider>
    );
}

export default WiringDiagramPage;
