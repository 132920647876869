import { Box, CircularProgress, IconButton } from '@mui/material'
import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const MissingInfoTreeView = (props) => {
    const { labelId, label, element, loading } = props
    const { connectorElement } = props
    const [expand, setExpand] = useState(true)

    return (
        <Box
            data-testid={labelId}
            sx={{
                mb: 1,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>

                <IconButton
                    sx={{
                        bgcolor: 'inherit',
                        '&:hover': { bgcolor: '#D9FFFE' },
                        ml: 0
                    }}
                    onClick={() => setExpand(!expand)}
                >
                    {
                        expand &&
                        <KeyboardArrowDownIcon color='primary' />
                    }
                    {
                        !expand && <KeyboardArrowRightIcon color='primary' />
                    }
                </IconButton>

                <div class='row' style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
                    <div class='col-sm-4'>
                        <p>
                            {label}
                        </p>
                    </div>
                    <div class='col-sm-4'>{connectorElement}</div>
                    <div class='col-sm-4'></div>
                </div>

            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                {
                    loading &&
                    <CircularProgress
                        data-testid='loading-indicator-id'
                        size='1.5rem' />
                }

            </Box>
            {
                !loading &&
                <Box sx={{ pl: 5, mt: 0.5, mb: 0.5 }}>
                    {
                        expand && element
                    }
                </Box>
            }


        </Box>
    )
}

export default MissingInfoTreeView;