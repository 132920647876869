import { createContext } from 'react';

export const UserContext = createContext({
  currUser: {
    username: '',
  },
});


export const AppContext = createContext({
  appContext: '',
  setAppContext: () => { },
});

export const MissingInfoContext = createContext({})