import envConfig from '../../../environments'
import { DIAGRAM_PATH } from '../../constants'
import { getAccessToken } from '../LoginPage/auth'

export const fetchMissingInfo = (diagramId, setMissingInfo, setLoading, currUser, setCurrUser) => {
    if (!diagramId || !currUser) {
        return
    }
    setLoading(true)
    const accessToken = getAccessToken(currUser, setCurrUser)
    accessToken.then(
        token => {
            fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_PATH}/${diagramId}`, {
                method: 'GET',
                headers: { 
                    'auth': token 
                },
            })
                .then(res => res.json())
                .then(data_res => {
                    if (data_res !== null) {
                        const pinData = data_res?.unrecognized_pins || []
                        let pinsMap = {}
                        for (let item of pinData) {
                            const componentName = item?.component_name
        
                            let pinNameArr = item?.name?.split('.') || []
                            const connectorId = `${pinNameArr?.[1]}.${pinNameArr?.[2]}`
        
                            pinsMap = {
                                ...pinsMap,
                                [componentName]: {
                                    ...(pinsMap[componentName] || {}),
                                    [connectorId]: [
                                        ...(pinsMap[componentName]?.[connectorId] || []),
                                        item
                                    ]
                                }
                            }
                        }
                        const totalUpdatedPins = Object.values(pinsMap)
                            .flatMap(connectorMap => Object.values(connectorMap))
                            .reduce((total, pins) => total + pins.filter(pin => pin?.editted_connector_id !== null).length, 0);
        
                        const totalUpdatedComponents = data_res?.unrecognized_components
                            ? data_res.unrecognized_components.filter(component => component?.editted_component_name !== null).length
                            : 0;
                        setMissingInfo({
                            connectors: data_res?.connectors || {},
                            components: data_res?.unrecognized_components || [],
                            totalPins: pinData.length,
                            pinsByComponentName: pinsMap,
                            updatedPins: totalUpdatedPins,
                            updatedComponents: totalUpdatedComponents,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
        
                }).finally(() => {
                    setLoading(false)
                })

        }
    )
}

export const updateMissingInfo = (diagramId, payload, setAlert, currUser, setCurrUser) => {
    if (!diagramId || !currUser) {
        return
    }
    const accessToken = getAccessToken(currUser, setCurrUser)
    accessToken.then(
        token => {
            fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_PATH}/${diagramId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    auth: token,
                },
                body: JSON.stringify(payload)
            })
                .then((resp) => {
                    if (resp.status === 204) {
                        setAlert({
                            severity: 'success',
                            title: 'Success',
                            message: 'Update missing info successfully!'
                        })
                    } else {
                        setAlert({
                            severity: 'error',
                            title: 'Error',
                            message: 'There are some issue while updating missing info!'
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setAlert({
                        severity: 'error',
                        title: 'Error',
                        message: 'Error while updating missing info!'
                    })
                })
        }
    )
}