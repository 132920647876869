import React from 'react';

const APIDocumentation = () => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe 
                id="api-doc-iframe"
                src='/api/api.html' 
                style={{ width: '100%', height: '100%', border: 'none' }} 
                title='API Documentation'
            />
        </div>
    );
};

export default APIDocumentation;
